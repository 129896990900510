import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import "../css/custom.scss";
import {
  Route,
  Link,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import logo from "../images/ormelogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faXmark,
  faGears,
  faUserTie,
  faUserPen,
  faUser,
  faGauge,
  faBullhorn,
  faTv,
  faCartFlatbed,
  faComments,
  faUsers,
  faRectangleAd,
  faVideo,
  faShareFromSquare,
  faTag,
  faTags
} from "@fortawesome/free-solid-svg-icons";
import { PercentProvider } from "../context/PercentProvider";
import { createBrowserHistory } from "history";
import BroadCast from "../pages/broadcast/broadcast";
import { Layout, Menu, notification, Spin } from "antd";
import Events from "../pages/events/events";
import HostEvents from "../pages/events/host-event";
import ScheduleEvent from "../pages/create-event/scheduleEvent/scheduleEvent";
import LiveEvent from "../pages/create-event/liveEvent/liveEvent";
import ControlRoom from "../pages/control-room/control-room";
import Ecommerce from "../pages/account/ecommerce/ecommerce";
import Footer from "../components/footer/footer";
import MainHeader from "../components/header/header";
import useEscape from "../reUsable/useEscapeKey";
import RecordedEvent from "../pages/recordEvent-room/recordEvent";
import RecordedShows from "../pages/shows/recordedShow";
import Dashboard from "../pages/dashboard/index";
import MonitorMentions from "../pages/ugc/monitorMentions/monitorMentions";
import MonitorHashTag from "../pages/ugc/monitorHastag/index";
import MonitorProfile from "../pages/ugc/monitorProfile/index";
import CategorySetup from "../pages/categorySetup/categorySetup";
import SocialSetup from "../pages/socialSetup/socialSetup";
import InventoryManagement from "../pages/inventoryManagement/inventory";
import HomeRole from "../pages/home/role";
import KbSetup from "../pages/kbSetup/kbSetup";
import ShippingSetup from "../pages/kbSetup/shippingSetup";
import Package from "../pages/public/public-package";
import Payment from "../pages/public/payment";
import BasicSetup from "../pages/basicSetup/basicSetup";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import InfluencerReview from "../pages/influencerReview";
import InstagramReels from "../pages/instagramReels";
import Shows from "../pages/shows/shows";
import ScheduleShow from "../pages/shows/scheduleShow";
import TotalOrder from "../pages/salesReport/totalOrder";
import MarketFee from "../pages/salesReport/marketFee";
import ReferralStats from "../pages/salesReport/referralSales";
import { useDispatch, useSelector } from "react-redux";
import Active from "../pages/inventory/active";
import SoldOut from "../pages/inventory/soldOut";
import Disabled from "../pages/inventory/disabled";
import Replaced from "../pages/inventory/replaced";
import RecordedReview from "../pages/influencerReview/recordedReviews";
import FeeStructure from "../pages/feeStructure/feeStructure";
import CreatorSales from "../pages/salesReport/creatorSales";
import EventBanUsers from "../pages/chatModeration/eventBanUsers";
import GlobalBanUsers from "../pages/chatModeration/globalBanUsers";
import BrandFollowers from "../pages/brandFollowers/brandFollowers";
import ReviewBoost from "../pages/boost/reviewBoost";
import InstagramBoost from "../pages/boost/InstagramBoost";
import LinkedProducts from "../pages/inventory/linkedproducts";
import UgcReview from "../pages/ugcReview";
import AdsManager from "../pages/adsManager";
import StripeSetup from "../pages/kbSetup/stripeSetup";
import PaymentMethod from "../pages/paymentMethod";
import DiscountList from "../pages/discount/discountList";
import DirectAuth from "../pages/public/direct-auth";
import { getProducts } from "../redux/actions/shopify.action";
import Swal from "sweetalert2";
import { validateUser } from "../redux/actions/login.action";
import CreateCompaign from "../pages/boost/createCompaign";
import CampaignManager from "../pages/boost";
import CampaignPayment from "../pages/boost/campaignPayment";
import ContentWithoutProducts from "../pages/contentWithoutProducts";
import CampaignPaymentManager from "../pages/campaignPayment";
import StoreSetup from "../pages/storeSetup/storeSetup";
import EventUsersComments from "../pages/chatModeration/eventUsersComments";
import UserComments from "../pages/chatModeration/comments";
import BrandShop from "../pages/brandShop/brandShop";

const { SubMenu } = Menu;
const { Sider, Content } = Layout;
export const history = createBrowserHistory({
  forceRefresh: true,
});

const App = () => {
  const { validate, getProduct } = useSelector((state) => {
    return state;
  });
  const ref = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [storeMenu, setStoreMenu] = useState(false);

  const [toggle, setToggle] = useState(false);
  const [userInfoData, setUserInfoData] = useState("");

  const [title, setTitle] = useState(location.pathname);

  let instagramCodeUrl = new URL(window.location.href);
  let code = new URLSearchParams(instagramCodeUrl.search);
  const notificationKey = `open${Date.now()}`;
  const load = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are syncing</p></div>;


  const reRender = (userData) => {
    setUserInfoData(userData);
  };
  useEffect(() => {
    if (
      location.pathname === "/account/total-orders" ||
      location.pathname === "/account/ecommerce" ||
      location.pathname === "/account/referral-sales" ||
      location.pathname === "/account/referral-fees" ||
      location.pathname === "/account/referral-deposit" ||
      location.pathname === "/account/creator-sales" ||
      location.pathname === "/account/event-sales" ||
      location.pathname === "/account/market-fees" ||
      location.pathname === "/ugc/monitor-mentions" ||
      location.pathname === "/ugc/monitor-hashtags" ||
      location.pathname === "/ugc/monitor-profile" ||
      location.pathname === "/account/categories" ||
      location.pathname === "/account/social" ||
      location.pathname === "/account/orders" ||
      location.pathname === "/inventory/manage" ||
      location.pathname === "/inventoryy/sold-out" ||
      location.pathname === "/account/feestructure" ||
      location.pathname === "/account/storesetup" ||
      location.pathname === "/chat/event-ban-users" ||
      location.pathname === "/chat/comments" ||
      location.pathname === "/chat/global-ban-users" ||
      location.pathname === "/brand-followers" ||
      location.pathname === "/boost/reviews" ||
      location.pathname === "/campaign-manager" ||
      location.pathname === "/campaign-payment" ||
      location.pathname === "/ads/adsmanager" ||
      location.pathname === "/boost/instagram" ||
      location.pathname === "/boost/shows" ||
      location.pathname === "/boost/events" ||
      location.pathname === "/instagram-reels"
    ) {
      changetitle(location.pathname);
    } else {
      changetitle(location.pathname.split("/")[1]);
    }
  }, [location]);
  useEffect(() => {
    mobileSidebar(toggle);
  }, [toggle]);

  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  const storeClick = () => {
    if (storeMenu) {
      setStoreMenu(false);
    } else {
      setStoreMenu(true);
    }
  };

  const mobileSidebar = (action) => {
    setToggle(action);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (storeMenu && ref.current && !ref.current.contains(e.target)) {
        setStoreMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [storeMenu]);
  const changetitle = (title) => {
    if (title === "schedule-event") {
      setTitle("Schedule Event");
    } else if (title === "schedule-show") {
      setTitle("Schedule Show");
    } else if (title === "live-event") {
      setTitle("Live Event");
    } else if (title === "home") {
      setTitle("Home");
    } else if (title === "events") {
      setTitle("Internal Live Event");
    } else if (title === "shows") {
      setTitle("Previous Shows");
    } else if (title === "host-event") {
      setTitle("Host Events");
    } else if (title === "control-room") {
      setTitle("Control Room");
    } else if (title === "/account/ecommerce") {
      setTitle("Settings");
    } else if (title === "/account/social") {
      setTitle("Settings");
    } else if (title === "/account/categories") {
      setTitle("Settings");
    } else if (title === "/account/feestructure") {
      setTitle("Settings");
    } else if (title === "/account/storesetup") {
      setTitle("Settings");
    } else if (title === "schedule-event-id") {
      setTitle("Events");
    } else if (title === "recorded-event") {
      setTitle("Recorded Event");
    } else if (title === "/account/total-orders") {
      setTitle("Total Sales");
    } else if (title === "/account/market-fees") {
      setTitle("Sales Report");
    } else if (title === "/account/referral-sales") {
      setTitle("Referral Sales");
    } else if (title === "/account/creator-sales") {
      setTitle("Review Sales");
    } else if (title === "/account/referral-deposit") {
      setTitle("Sales Report");
    } else if (title === "dashboard") {
      setTitle("Dashboard");
    } else if (title === "/ugc/monitor-mentions") {
      setTitle("UGC");
    } else if (title === "/ugc/monitor-profile") {
      setTitle("UGC");
    } else if (title === "/ugc/monitor-hashtags") {
      setTitle("UGC");
    } else if (title === "/account/event-sales") {
      setTitle("Sales Report");
    } else if (title === "account") {
      setTitle("Order Details");
    } else if (title === "/ugc/profiles") {
      setTitle("Profile Monitoring");
    } else if (title === "/account/order-detail") {
      setTitle("Order Detail");
    } else if (title === "inventory") {
      setTitle("SKU Management");
    } else if (title === "brandsetup") {
      setTitle("Settings");
    } else if (title === "paymentmethod") {
      setTitle("Settings");
    } else if (title === "shippingsetup") {
      setTitle("Settings");
    }
    else if (title === "basicsetup") {
      setTitle("Settings");
    }
    else if (title === "brandshop") {
      setTitle("Brand Shop");
    }
    else if (title === "privacy-policy") {
      setTitle("Privacy Policy");
    } else if (title === "terms-use") {
      setTitle("Terms & Conditions");
    } else if (title === "influencer-review") {
      setTitle("Brand Videos");
    } else if (title === "instagram-reels") {
    } else if (title === "content-withoutproducts") {
      setTitle("Upload Videos");
    } else if (title === "instagram-reels") {
      setTitle("Instagram Reels");
    }
    else if (title === "ugc-reviews") {
      setTitle("User Reviews");
    }
    else if (title === "discount") {
      setTitle("Discount");
    }
    else if (title === "schedule-show-id") {
      setTitle("Shows");
    } else if (title === "/chat/event-ban-users") {
      setTitle("User Control");
    } else if (title === "/chat/comments") {
      setTitle("User Control");
    }
    else if (title === "/chat/global-ban-users") {
      setTitle("Chat Moderation");
    } else if (title === "followers" || title === "/brand-followers") {
      setTitle("Brand Followers");
    } else if (title === "campaign-management" || title === "/boost/reviews" || title === "/campaign-manager" || title === "/campaign-payment") {
      // setTitle("Boost");
      setTitle("Ads Manager");
    } else if (title === "Boost" || title === "/boost/instagram") {
      setTitle("Instagram Boost");
    }
    else if (title === "/ads/adsmanager") {
      setTitle("Ads Manager ");
    }
    else if (title === "Boost" || title === "/boost/shows") {
      setTitle("Boost");
    }
    else if (title === "Boost" || title === "/boost/events") {
      setTitle("Boost");
    }
    else if (title === "active" || "/inventoryy/active") {
      setTitle("Inventory");
    }
    else if (title === "active" || "/inventoryy/linkedproducts") {
      setTitle("Inventory");
    }
    else if (title === "sold-out" || "/inventoryy/sold-out") {
      setTitle("Inventory");
    }
    else if (title === "disabled" || "/inventoryy/disabled") {
      setTitle("Inventory");
    }
    else if (title === "replaced" || "/inventoryy/replaced") {
      setTitle("Inventory");
    }
  };
  const rootSubmenuKeys = [
    "crm",
    "inventory",
    "accounting",
    "settings",
    "ugc",
    "content-management",
    "chat-moderation",
    "boost",
    "adsmanager",
    "social-store-management",
    "ugc-reviews",
  ];
  const [openKeys, setOpenKeys] = useState([]);
  useEffect(() => {
    if (location?.state?.openKey) {
      setOpenKeys([location?.state?.openKey])
    }
  }, [location?.state])
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEscape(() => setToggle(false));
  const syncData = () => {

    Swal.fire({
      title: `Are You Sure You Want To Sync Products?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        notification.open({
          message: 'Product Syncing...',
          // description:
          //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
          description: load,
          key: notificationKey,
          duration: 0,
        })
        dispatch(getProducts(validate?.payload?.message?.token))
          .then((res) => {
            if (res?.type === "GET_PRODUCT_ERROR") {
              notification.destroy({ key: notificationKey })
              notification.error({
                message: res?.payload?.data?.message,
                className: "toast-error",
              });
            } else {
              notification.destroy({ key: notificationKey })
              notification.success({
                message: res?.payload?.message,
                className: "toast-success",
              });
              dispatch(validateUser(validate?.payload?.message?.token));
            }
          })
          .catch((err) => {
            notification.destroy({ key: notificationKey })

            notification.error({
              message: "Sync Failed",
              className: "toast-error",
            });
            console.log(err.response, "err");
          });
      }
    });
  }
  return (
    <PercentProvider>
      <Layout className="generic-style">
        {validate?.payload?.message?.account_type === "brand" ? (
          <>
            {validate?.payload?.message?.package ? (
              <>
                <Sider
                  className={`sidebar-main scrollbar-style ${!toggle ? "toggle-sidebar" : ""
                    } `}
                >
                  <div className="logo-area">
                    <div className="logo">
                      <Link
                        to="/home"
                        onClick={() => {
                          changetitle("home");
                          setToggle(false);
                        }}
                      >
                        <img src={logo} alt="logo" />
                      </Link>
                    </div>
                    <div className="menu-close d-xl-none">
                      <Link
                        to="#"
                        onClick={() => {
                          setToggle(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </Link>
                    </div>
                  </div>

                  <h5 className="wordspace-hd">Workspace</h5>

                  <div
                    className="workspace-menu"
                    ref={ref}
                    onClick={() => storeClick()}
                  >
                    <div className="store-menu">
                      <div className="workspace-icon">
                        <FontAwesomeIcon icon={faStore} />
                      </div>
                      <div className="workspace-menu-item">
                        {/* <div className="store-name-txt">{userInfoo?.message?.data?.name}</div> */}
                        <div className="store-name-txt">
                          ORME STORE
                          <br />
                          <strong>{userInfoData?.message?.data?.name
                            ? userInfoData?.message?.data?.name
                            : validate?.payload?.message?.name}</strong>
                        </div>
                        {/* <div className="store-owner-txt">{userInfo?.name}</div> */}
                      </div>
                    </div>
                  </div>
                  <Menu
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    mode="inline"
                    className="menu-style-new"
                    defaultSelectedKeys={location.pathname.split("/").length > 2 ? location.pathname.split("/")[2] : location.pathname.split("/")[1]}
                  // defaultSelectedKeys={["/account/ecommerce"]}
                  >
                    <Menu.Item
                      key="role"
                      style={{
                        backgroundColor: title === "Internal Live Event" && "white",
                        fontWeight: title === "Internal Live Event" && "400",
                      }}
                    >
                      <Link
                        className={`${title === "Internal Live Event" && "unSelectedMenu"}`}
                        to="home"
                        onClick={() => {
                          changetitle("home");
                          setToggle(false);
                          onOpenChange([])
                        }}
                      >
                        <FontAwesomeIcon className="" icon={faUserPen} />
                        <span className="nav-text">User Role</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="brandshop">
                      <Link
                        to="brandshop"
                        onClick={() => {
                          changetitle("brandshop");
                          setToggle(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faStore} />
                        <span className="nav-text">Brand Shop</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="dashboard">
                      <Link
                        to="dashboard"
                        onClick={() => {
                          changetitle("dashboard");
                          setToggle(false);
                          onOpenChange([])
                        }}
                      >
                        <FontAwesomeIcon icon={faGauge} />
                        <span className="nav-text">Dashboard</span>
                      </Link>
                    </Menu.Item>
                    {/* <SubMenu
                      className="submenu-main"
                      key="crm"
                      disabled={true}
                      title={
                        <span>
                          <span class="fa fa-users menu-icon-direct"></span>
                          <span className="nav-text-color">CRM</span>
                        </span>
                      }
                    >

                    </SubMenu> */}
                    <SubMenu
                      className="submenu-main"
                      key="inventory"
                      title={
                        <span>
                          <FontAwesomeIcon icon={faCartFlatbed} className="menu-icon-direct" />
                          <span className="nav-text-color">Inventory</span>
                        </span>
                      }
                    >
                      <Menu.Item key="active"
                        className={` ${(location?.state?.key === "active") &&
                          "ant-menu-item-selected"
                          } `}
                      >
                        <Link
                          className={`${(location?.state?.key === "ecommerce" && "unSelectedMenu")}`}
                          to="/inventoryy/active"
                          onClick={() => {
                            changetitle("active");
                            setToggle(false);
                          }}
                        >
                          Manage Ecom SKU
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="linkedproducts"
                      >
                        <Link

                          to="/inventoryy/linkedproducts"
                          onClick={() => {
                            changetitle("linkedproducts");
                            setToggle(false);
                          }}
                        >
                          Linked SKU
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="sold-out">
                        <Link

                          to="/inventoryy/sold-out"
                          onClick={() => {
                            changetitle("sold-out");
                            setToggle(false);
                          }}
                        >
                          Sold Out SKU
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="disabled">
                        <Link
                          to="/inventoryy/disabled"
                          onClick={() => {
                            changetitle("disabled");
                            setToggle(false);
                          }}
                        >
                          Disabled SKU
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="replaced">
                        <Link
                          to="/inventoryy/replaced"
                          onClick={() => {
                            changetitle("replaced");
                            setToggle(false);
                          }}
                        >
                          Replaced SKU
                        </Link>
                      </Menu.Item>
                      <Menu.Item disabled={(getProduct && getProduct?.loading) || !(validate?.payload?.message?.salesforce || validate?.payload?.message?.shopify || validate?.payload?.message?.magento || validate?.payload?.message?.scoc || validate?.payload?.message?.squarespace)}>
                        <div className="d-flex">
                          <Link
                            disabled={getProduct && getProduct?.loading}
                            // to="/inventoryy/replaced"
                            onClick={() => {
                              // changetitle("replaced");
                              // setToggle(false);
                              syncData()
                            }}
                          >
                            Sync Inventory
                          </Link>
                          {getProduct && getProduct?.loading &&
                            <span className="spin-color mx-2">
                              <Spin size="small" />
                            </span>
                          }
                        </div>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      key="events"
                      className={` ${(location?.state?.key === "ecommerce" || location?.state?.key === "categories" || location?.state?.key === "active" && "unSelectedMenu")} ${(title === "Events" || title === "Schedule Event" || title === "Internal Live Event") &&
                        "ant-menu-item-selected"
                        }`}
                    >
                      <Link
                        className={`${(location?.state?.key === "ecommerce" || location?.state?.key === "categories" || location?.state?.key === "active" && "unSelectedMenu")}`}
                        to="events1"
                        onClick={() => {
                          changetitle("events");
                          setToggle(false);
                          onOpenChange([])
                        }}
                      >
                        <FontAwesomeIcon className="" icon={faUserTie} />
                        <span className="nav-text">Internal Live Event</span>
                      </Link>
                    </Menu.Item>
                    <SubMenu
                      className="submenu-main"
                      key="content-management"
                      title={
                        <span>

                          <FontAwesomeIcon className="menu-icon-direct" icon={faShareFromSquare} />
                          <span className="nav-text-color">Content Management</span>
                        </span>
                      }
                    >
                      <Menu.Item
                        key="shows"
                      // className={` ${(title === "Shows" || title === "Schedule Show") &&
                      //   "ant-menu-item-selected"
                      //   } `}
                      >
                        <Link
                          className={`${(location?.state?.key === "ecommerce" || location?.state?.key === "categories" || location?.state?.key === "active" && "unSelectedMenu")}`}
                          to="shows"
                          onClick={() => {
                            changetitle("shows");
                            setToggle(false);
                          }}
                        >
                          {/* <FontAwesomeIcon
                          className="menu-icons fa-sm"
                          icon={faTv}
                        /> */}
                          {/* <span className="nav-text left-space"> */}
                          Previous Shows
                          {/* </span> */}
                        </Link>
                      </Menu.Item>

                      {/* <SubMenu */}
                      {/* // className="submenu-main"
                    // key="ugc"
                    // title={ */}
                      {/* // <span> */}
                      {/* <FontAwesomeIcon icon={faAt} /> */}
                      {/* <span class="fa fa-at  menu-icon-direct"></span> */}
                      {/* <span>UGC</span> */}
                      {/* // </span> */}
                      {/* // } */}
                      {/* // > */}
                      {/* <Menu.Item>
                      <Link
                        to="/ugc/monitor-mentions"
                        onClick={() => {
                          changetitle("monitorMentions");
                          setToggle(false);
                        }}
                      >
                        Monitor Mentions
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/ugc/monitor-hashtags"
                        onClick={() => {
                          changetitle("monitorHashTags");
                          setToggle(false);
                        }}
                      >
                        Monitor Hashtags
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/ugc/monitor-profile"
                        onClick={() => {
                          changetitle("ugcProfile");
                          setToggle(false);
                        }}
                      >
                        Monitor Profiles
                      </Link>
                    </Menu.Item> */}
                      {/* </SubMenu> */}
                      <Menu.Item key="influencer-review">
                        <Link
                          className={`${(location?.state?.key === "ecommerce" || location?.state?.key === "categories" || location?.state?.key === "active" && "unSelectedMenu")}`}
                          to="influencer-review1"
                          onClick={() => {
                            changetitle("influencer-review");
                            setToggle(false);
                          }}
                        >
                          {/* <FontAwesomeIcon className="" icon={faBullhorn} /> */}
                          {/* <span className="nav-text"> */}
                          Brand Videos
                          {/* </span> */}
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item key="content-withoutproducts">
                        <Link
                          className={`${(location?.state?.key === "ecommerce" || location?.state?.key === "categories" || location?.state?.key === "active" && "unSelectedMenu")}`}
                          to="content-withoutproducts1"
                          onClick={() => {
                            changetitle("content-withoutproducts");
                            setToggle(false);
                          }}
                        >
                          Upload Videos
                        </Link>
                      </Menu.Item> */}


                      <Menu.Item key="instagram-reels">
                        <Link
                          to="instagram-reels1"
                          onClick={() => {
                            changetitle("instagram-reels");
                            setToggle(false);
                          }}

                        >
                          Instagram Reels
                        </Link>
                      </Menu.Item>
                    </SubMenu>

                    <Menu.Item key="ugc-reviews">
                      <Link
                        to="ugc-reviews1"
                        onClick={() => {
                          changetitle("ugc-reviews");
                          setToggle(false);
                          onOpenChange([])
                        }}
                      >
                        <FontAwesomeIcon className="" icon={faVideo} />
                        <span className="nav-text">User Reviews</span>
                      </Link>
                    </Menu.Item>
                    {!validate?.payload?.message?.magento ?

                      <Menu.Item key="discount">
                        <Link
                          to="discount"
                          onClick={() => {
                            changetitle("discount");
                            setToggle(false);
                            onOpenChange([])
                          }}
                        >
                          <FontAwesomeIcon className="" icon={faTags} />
                          <span className="nav-text">Discount Management</span>
                        </Link>
                      </Menu.Item>
                      : null}

                    <SubMenu
                      className="submenu-main"
                      key="chat-moderation"
                      title={
                        <span>
                          <FontAwesomeIcon className="menu-icon-direct" icon={faComments} />
                          <span className="nav-text-color">Chat Moderation</span>
                        </span>
                      }
                    >
                      <Menu.Item key="comments">
                        <Link
                          to="/chat/comments"
                          onClick={() => {
                            changetitle("comments");
                            setToggle(false);
                          }}
                        >
                          Comments
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="event-ban-users">
                        <Link
                          to="/chat/event-ban-users"
                          onClick={() => {
                            changetitle("event-ban-users");
                            setToggle(false);
                          }}
                        >
                          User Control
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item
                        disabled>
                        <Link
                          to="/chat/global-ban-users"
                          onClick={() => {
                            changetitle("global-ban-users");
                            setToggle(false);
                          }}
                        >
                          Global Ban Users
                        </Link>
                      </Menu.Item> */}
                    </SubMenu>
                    <SubMenu
                      className="submenu-main"
                      key="accounting"
                      title={
                        <span>
                          <span class="fa fa-line-chart menu-icon-direct"></span>
                          <span className="nav-text-color">Sales Report</span>
                        </span>
                      }
                    >
                      <Menu.Item key="total-orders">
                        <Link
                          to="/account/total-orders"
                          onClick={() => {
                            changetitle("sales");
                            setToggle(false);
                          }}
                        >
                          Total Sales
                        </Link>
                      </Menu.Item>

                      {/* <Menu.Item disabled={true}>
                        <Link
                          to="/account/referral-sales"
                          onClick={() => {
                            changetitle("referralSales");
                            setToggle(false);
                          }}
                        >
                          Referral Sales
                        </Link>
                      </Menu.Item>
                      <Menu.Item disabled={true}
                      >
                        <Link
                          to="/account/creator-sales"
                          onClick={() => {
                            changetitle("creatorSales");
                            setToggle(false);
                          }}
                        >
                          Review Sales
                        </Link>
                      </Menu.Item>

                      <Menu.Item disabled={true}>
                        <Link
                          to="/account/market-fees"
                          onClick={() => {
                            changetitle("marketFees");
                            setToggle(false);
                          }}
                        >
                          Market Fees
                        </Link>
                      </Menu.Item>

                      <Menu.Item disabled={true}>
                        <Link
                          to="/account/referral-deposit"
                          onClick={() => {
                            changetitle("referralDeposit");
                            setToggle(false);
                          }}
                        >
                          Payout
                        </Link>
                      </Menu.Item> */}
                    </SubMenu>

                    <Menu.Item
                      key="brand-followers"
                    >
                      <Link
                        to="/brand-followers"
                        onClick={() => {
                          changetitle("followers");
                          setToggle(false);
                          onOpenChange([])
                        }}
                      >
                        <FontAwesomeIcon icon={faUsers} />
                        <span className="nav-text">Followers</span>
                      </Link>
                    </Menu.Item>

                    <SubMenu
                      className="submenu-main bottom-fixed-aut"
                      key="boost"
                      title={
                        <span>
                          <FontAwesomeIcon icon={faRectangleAd} className="color-main" />
                          <span className="nav-text-color">Ads Manager</span>
                        </span>
                      }
                    >
                      <Menu.Item key="content-withoutproducts">
                        <Link
                          className={`${(location?.state?.key === "ecommerce" || location?.state?.key === "categories" || location?.state?.key === "active" && "unSelectedMenu")}`}
                          to="content-withoutproducts1"
                          onClick={() => {
                            changetitle("content-withoutproducts");
                            setToggle(false);
                          }}
                        >
                          Upload Videos
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="campaign-manager">
                        <Link
                          className={`${location?.state?.key === "campaign-payment" && "unSelectedMenu"}`}
                          to="/campaign-manager1"
                          onClick={() => {
                            changetitle("Ads Manager");
                            setToggle(false);
                          }}
                        >
                          Campaign Manager
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="reviews">
                        <Link
                          to="/boost/reviews"
                          onClick={() => {
                            changetitle("Boost");
                            setToggle(false);
                          }}
                        >
                          Campaign Report
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="campaign-payment"
                        className={` ${(location?.state?.key === "campaign-payment") &&
                          "ant-menu-item-selected"
                          } `}
                      >
                        <Link
                          to="/campaign-payment"
                          onClick={() => {
                            changetitle("Ads Manager");
                            setToggle(false);
                          }}
                        >
                          Payment
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item disabled>
                        <Link
                          to="/boost/instagram"
                          onClick={() => {
                            changetitle("Instagram Boost");
                            setToggle(false);
                          }}
                        >
                          Instagram
                        </Link>
                      </Menu.Item>
                      <Menu.Item disabled>
                        <Link
                          to="/boost/shows"
                          onClick={() => {
                            changetitle("Boost");
                            setToggle(false);
                          }}
                        >
                          Shows
                        </Link>
                      </Menu.Item>
                      <Menu.Item disabled>
                        <Link
                          to="/boost/events"
                          onClick={() => {
                            changetitle("Boost");
                            setToggle(false);
                          }}
                        >
                          Live Events
                        </Link>
                      </Menu.Item> */}
                    </SubMenu>
                    <SubMenu
                      className="submenu-main bottom-fixed-aut"
                      key="settings"
                      title={
                        <span>
                          <FontAwesomeIcon icon={faGears} />
                          <span className="nav-text-color">Settings</span>
                        </span>
                      }
                    >
                      <Menu.Item key="brandsetup">
                        <Link
                          to="brandsetup"
                          onClick={() => {
                            changetitle("brandsetup");
                            setToggle(false);
                          }}
                        >
                          Agreement Setup
                        </Link>
                      </Menu.Item>
                      {
                        validate?.payload?.message?.contract?.is_stripe_connect_user &&
                        <Menu.Item key="paymentmethod">
                          <Link
                            to="paymentmethod1"
                            onClick={() => {
                              changetitle("paymentmethod");
                              setToggle(false);
                            }}
                          >
                            Payment Setup
                          </Link>
                        </Menu.Item>}
                      <Menu.Item key="ecommerce"
                        className={` ${(location?.state?.key === "ecommerce") &&
                          "ant-menu-item-selected"
                          } `}>
                        <Link
                          to="/account/ecommerce"
                          onClick={() => {
                            changetitle("ecommerce");
                            setToggle(false);
                          }}
                        >
                          Ecommerce Setup
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item
                      >
                        <Link
                          to="/account/storesetup"
                          onClick={() => {
                            changetitle("storesetup");
                            setToggle(false);
                          }}
                        >
                          Store Setup
                        </Link>
                      </Menu.Item> */}
                      <Menu.Item>
                        <Link
                          to="/account/social"
                          onClick={() => {
                            changetitle("socialsetup");
                            setToggle(false);
                          }}
                        >
                          Social Setup
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="basicsetup">
                        <Link
                          to="basicsetup"
                          onClick={() => {
                            changetitle("basicsetup");
                            setToggle(false);
                          }}
                        >
                          Basic Setup
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="categories"
                        className={` ${(location?.state?.key === "categories") &&
                          "ant-menu-item-selected"
                          } `}
                      >
                        <Link
                          to="/account/categories"
                          onClick={() => {
                            changetitle("categories");
                            setToggle(false);
                          }}
                        >
                          Category Setup
                        </Link>
                      </Menu.Item>
                      {validate?.payload?.message?.shopify || validate?.payload?.message?.squarespace ?

                        <Menu.Item key="shippingsetup">
                          <Link
                            to="shippingsetup"
                            onClick={() => {
                              changetitle("shippingsetup");
                              setToggle(false);
                            }}
                          >
                            Shipping Setup
                          </Link>
                        </Menu.Item>
                        : null}







                      {/* <Menu.Item
                      >
                        <Link
                          to="/account/feestructure"
                          onClick={() => {
                            changetitle("feestructure");
                            setToggle(false);
                          }}
                        >
                          Fee Structure
                        </Link>
                      </Menu.Item> */}


                    </SubMenu>
                  </Menu>
                </Sider>
                <Layout className="site-layout">
                  <MainHeader
                    title={title}
                    toggle1={toggle}
                    mobileSidebar={mobileSidebar}
                  />
                  <Content className="dashboard-content-container">
                    <div className="dashboard-content-full">
                      <div
                        className={`dashboard-inner-content ${title === "Recorded Event" ? "recorded-room-width" : ""
                          } ${title === "Social Store" ? "store-style" : ""}`}
                      >
                        <Routes>
                          <Route path="/home" exact element={<HomeRole />} />
                          <Route path="/auth/:token" element={<DirectAuth />} />
                          <Route
                            path="/dashboard"
                            exact
                            element={<Dashboard />}
                          />
                          <Route
                            path="/brandshop"
                            exact
                            element={<BrandShop reRender={reRender} />}
                          />
                          <Route path="/events" exact element={<Events />} />
                          <Route path="/shows" exact element={<Shows />} />

                          <Route
                            path="/schedule-event"
                            exact
                            element={<ScheduleEvent />}
                          />
                          <Route
                            path="/schedule-show"
                            exact
                            element={<ScheduleShow />}
                          />

                          <Route
                            path="/schedule-event-id/:id"
                            exact
                            element={<ScheduleEvent />}
                          />
                          <Route
                            path="/schedule-show-id/:id"
                            exact
                            element={<ScheduleShow />}
                          />
                          <Route
                            path="/live-event"
                            exact
                            element={<LiveEvent />}
                          />

                          <Route
                            path="/control-room/:id"
                            exact
                            element={<ControlRoom />}
                          />
                          <Route
                            path="/account/ecommerce"
                            exact
                            element={<Ecommerce />}
                          />
                          <Route
                            path="/account/categories"
                            exact
                            element={<CategorySetup />}
                          />
                          <Route
                            path="/account/social"
                            exact
                            element={<SocialSetup />}
                          />
                          {/* <Route
                            path="/account/feestructure"
                            exact
                            element={<FeeStructure />}
                          /> */}
                          {/* <Route
                            path="/account/storesetup"
                            exact
                            element={<StoreSetup />}
                          /> */}

                          <Route
                            path="/inventory/manage/:id"
                            exact
                            element={<InventoryManagement />}
                          />
                          <Route
                            path="/inventoryy/active"
                            exact
                            element={<Active />}
                          />
                          <Route
                            path="/inventoryy/linkedproducts"
                            exact
                            element={<LinkedProducts />}
                          />
                          <Route
                            path="/inventoryy/sold-out"
                            exact
                            element={<SoldOut />}
                          />
                          <Route
                            path="/inventoryy/disabled"
                            exact
                            element={<Disabled />}
                          />
                          <Route
                            path="/inventoryy/replaced"
                            exact
                            element={<Replaced />}
                          />

                          <Route
                            path="/ugc/monitor-profile"
                            exact
                            element={<MonitorProfile />}
                          />
                          <Route
                            path="/ugc/monitor-mentions"
                            exact
                            element={<MonitorMentions />}
                          />
                          <Route
                            path="/ugc/monitor-hashtags"
                            exact
                            element={<MonitorHashTag />}
                          />
                          <Route
                            path="/account/total-orders"
                            exact
                            element={<TotalOrder />}
                          />
                          <Route
                            path="/account/referral-sales"
                            exact
                            element={<ReferralStats />}
                          />
                          <Route
                            path="/account/creator-sales"
                            exact
                            element={<CreatorSales />}
                          />
                          <Route
                            path="/account/market-fees"
                            exact
                            element={<MarketFee />}
                          />
                          <Route
                            path="/recorded-event/:id"
                            exact
                            element={<RecordedEvent />}
                          />
                          <Route
                            path="/recorded-shows/:id"
                            exact
                            element={<RecordedShows />}
                          />
                          <Route
                            path="/events1"
                            element={<Redirect to="/events" />}
                          />
                          <Route
                            path="/instagram-reels"
                            element={<InstagramReels />}
                          />
                          <Route
                            path="/instagram-reels1"
                            element={<Redirect to="/instagram-reels" />}
                          />
                          <Route
                            path="/influencer-review"
                            element={<InfluencerReview />}
                          />
                          <Route
                            path="/influencer-review/:id"
                            exact
                            element={<RecordedReview />}
                          />
                          <Route
                            path="/content-withoutproducts"
                            element={<ContentWithoutProducts />}
                          />
                          {!validate?.payload?.message?.magento &&
                            <Route
                              path="/discount"
                              exact
                              element={<DiscountList />}
                            />
                          }
                          <Route
                            path="/ugc-reviews"
                            exact
                            element={<UgcReview />}
                          />
                          <Route
                            path="/ugc-reviews1"
                            exact
                            element={<Redirect to="/ugc-reviews" />}
                          />
                          <Route
                            path="/ugc-reviews/:id"
                            exact
                            element={<RecordedReview />}
                          />

                          <Route
                            path="/instagram-reels/:id"
                            exact
                            element={<RecordedReview />}
                          />
                          <Route
                            path="/influencer-review1"
                            element={<Redirect to="/influencer-review" />}
                          />
                          <Route
                            path="/content-withoutproducts1"
                            element={<Redirect to="/content-withoutproducts" />}
                          />
                          <Route
                            path="chat/event-ban-users"
                            exact
                            element={<EventBanUsers />}
                          />
                          <Route
                            path="chat/comments"
                            exact
                            element={<UserComments />}
                          />
                          <Route
                            path="chat/global-ban-users"
                            exact
                            element={<GlobalBanUsers />}
                          />
                          <Route
                            path="/brand-followers"
                            exact
                            element={<BrandFollowers />}
                          />
                          <Route
                            path="/boost/reviews"
                            exact
                            element={<ReviewBoost />}
                          />
                          <Route
                            path="/campaign-manager"
                            exact
                            // element={<CreateCompaign />}
                            element={<CampaignManager />}
                          />
                          <Route
                            path="/campaign-manager1"
                            element={<Redirect to="/campaign-manager" />}
                          />
                          <Route
                            path="/campaign-payment"
                            exact
                            // element={<CreateCompaign />}
                            element={<CampaignPaymentManager />}
                          />
                          <Route
                            path="/boost/Instagram"
                            exact
                            element={<InstagramBoost />}
                          />
                          <Route
                            path="/boost/shows"
                            exact
                            element={<BrandFollowers />}
                          />
                          <Route
                            path="/boost/events"
                            exact
                            element={<BrandFollowers />}
                          />
                          <Route
                            path="/ads/adsmanager"
                            exact
                            element={<AdsManager />}
                          />
                          <Route
                            path="/privacy-policy"
                            element={<PublicPrivacyPolicy />}
                          />
                          <Route path="/terms-use" element={<PublicTermsUse />} />

                          {instagramCodeUrl.href.includes("code") ? (
                            <Route
                              path="*"
                              element={
                                <Redirect to={`/account/social/?${code}`} />
                              }
                            />
                          ) : (
                            <Route path="*" element={<Redirect to="/home" />} />
                          )}

                          <Route path="/brandsetup" exact element={<KbSetup />} />
                          {
                            validate?.payload?.message?.contract?.is_stripe_connect_user &&
                            <>
                              <Route path="/paymentmethod" exact element={<PaymentMethod />} />
                              <Route path="/paymentmethod1" element={<Redirect to="/paymentmethod" />} />
                            </>
                          }
                          <Route path="/shippingsetup" exact element={<ShippingSetup />} />
                          <Route
                            path="/basicsetup"
                            exact
                            element={<BasicSetup reRender={reRender} />}
                          />
                        </Routes>
                      </div>

                      <Footer />
                    </div>
                  </Content>
                </Layout>
              </>
            ) : (
              <>
                <Link to="/package"></Link>
                <Routes>
                  <Route path="/package" exact element={<Package />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="*" element={<Redirect to="/package" />} />
                </Routes>
              </>
            )}
          </>
        ) : (
          <>
            <Sider className={`sidebar-main ${!toggle ? "toggle-sidebar" : ""} `}>
              <div className="logo-area">
                <div className="logo">
                  <Link
                    to="/home"
                    onClick={() => {
                      changetitle("home");
                      setToggle(false);
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <div className="menu-close d-xl-none">
                  <Link
                    to="#"
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </Link>
                </div>
              </div>

              <h5 className="wordspace-hd">Workspace</h5>

              <div className="workspace-menu" ref={ref}>
                <div className="store-menu">
                  <div className="workspace-icon">
                    <FontAwesomeIcon icon={faStore} />
                  </div>
                  <div className="workspace-menu-item">
                    <div className="store-name-txt">
                      {validate?.payload?.message?.name}
                    </div>
                  </div>
                </div>
              </div>

              <Menu
                // theme="dark"
                mode="inline"
                selectedKeys={[location.pathname]}
                defaultSelectedKeys={location.pathname.split("/")[1]}
              >
                <Menu.Item key="host-event">
                  <Link
                    to="host-event1"
                    onClick={() => {
                      changetitle("host-event");
                      setToggle(false);
                    }}
                  >
                    <FontAwesomeIcon className="fspx-15" icon={faUser} />
                    <span className="nav-text">Events</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <MainHeader
                title={title}
                toggle1={toggle}
                mobileSidebar={mobileSidebar}
              />
              <Content className="dashboard-content-container">
                <div className="dashboard-content-full">
                  <div className={`dashboard-inner-content`}>
                    <Routes>
                      <Route path="/host-event" exact element={<HostEvents />} />
                      <Route
                        path="/control-room/:id"
                        exact
                        element={<ControlRoom />}
                      />
                      <Route
                        path="/host-event1"
                        element={<Redirect to="/host-event" />}
                      />
                      <Route path="*" element={<Redirect to="/host-event" />} />
                      <Route
                        path="/recorded-event/:id"
                        exact
                        element={<RecordedEvent />}
                      />
                    </Routes>
                  </div>

                  <Footer />
                </div>
              </Content>
            </Layout>
          </>
        )}
      </Layout>
    </PercentProvider>
  );
};

export default App;
