import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStepsForm } from "sunflower-antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getShopifyDetail,
  getProducts,
  postShopify,
  postSalesForce,
  getSalesForceDetail,
  postMagento,
  getMagentoDetail,
  generateKey,
  postCustomShop,
  getCustomShopDetail,
  addProduct,
  postSiteCore,
  getSiteCoreDetail,
  postSquareSpace,
  getSquareSpaceDetail,
  getSquareSpaceAccessToken
} from "../../../redux/actions/shopify.action";
import { validateUser } from "../../../redux/actions/login.action";
import {
  faRotate,
  faXmark,
  faPenToSquare,
  faUpload,
  faCopy,
  faClipboard,
  faFileClipboard,
  faArrowUpFromBracket,
  faCloudArrowUp,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import shopifyLogo from "../../../images/shopify-logo.png";
import oracleLogo from "../../../images/oracle-logo.png";
import magentoLogo from "../../../images/magento-logo.png";
import ordercloudLogo from "../../../images/ordercloud-logo.svg";
import axios from "axios";
import Swal from "sweetalert2";
import {
  notification,
  Select,
  Form,
  Input,
  Spin,
  Button,
  Tabs,
  Modal,
  message,
  Upload,
  Tooltip,
  Progress,
  Popover
} from "antd";
import custom_sample from "../../../video/custom_sample.csv"
import admin_api_token from "../../../images/admin-api-token.png"
import admin_api_key from "../../../images/admin-api-key.jpg"
import square_space_api_key from "../../../images/square-space-api-key.png"
import { BASEURL } from "../../../config";
// import Dragger from "antd/lib/upload/Dragger";
import { AudioFileOutlined, CopyAllOutlined, InboxOutlined } from "@mui/icons-material";
import { CopyOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from "moment";

const { TabPane } = Tabs;

const { Option } = Select;

const clientId = process.env.REACT_APP_SQUARESPACE_CLIENT_ID;
const redirectUri = encodeURIComponent(process.env.REACT_APP_SQUARESPACE_REDIRECT_URI);
const scope = encodeURIComponent('website.orders,website.orders.read,website.inventory,website.inventory.read,website.products,website.products.read'); // Adjust the scope according to your needs
const state = encodeURIComponent(process.env.REACT_APP_SQUARESPACE_STATE); // You should generate a random state value to prevent CSRF attacks
// const websiteId = 'https://warpweft.squarespace.com/'; // This is optional, depending on the user's login state
const websiteId = '66d6e1ed937f88752b6ee02c'; // This is optional, depending on the user's login state
const accessType = 'offline'; // Optional, for long-term API access

let authorizationUrl = `https://login.squarespace.com/api/1/login/oauth/provider/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

// Append website_id if it's applicable
// if (websiteId) {
//   authorizationUrl += `&website_id=${websiteId}`;
// }

// Append access_type if needed
if (accessType) {
  authorizationUrl += `&access_type=${accessType}`;
}


function EcommerceSetup() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [formState, setFormState] = useState("add");
  const [spinner, setSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState("shopify");
  const [handlerStore, setHandlerStore] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMagento, setIsModalOpenMagento] = useState(false);
  const [isModalOpenMagentoSecond, setIsModalOpenMagentoSecond] = useState(false);
  const [isModalOpenCloud, setIsModalOpenCloud] = useState(false);
  const [isModalSalesForce, setIsModalSalesForce] = useState(false);
  const [isModalOpenSiteCore, setIsModalOpenSiteCore] = useState(false);
  const [isModalOpenSquareSpace, setIsModalOpenSquareSpace] = useState(false);
  const [siteCoreBtn, setSiteCoreBtn] = useState(false);
  const [isModalCustom, setIsModalCustom] = useState(false);
  const [isModalCustomSecond, setIsModalCustomSecond] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);
  const [externalKey, setExternalKey] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [multiWebsites, setMultiWebsites] = useState([]);
  const [selectedWebsites, setSelectedWebsites] = useState();
  const [CSVFile, setCSVFile] = useState();
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [squareSpaceAuthLoader, setSquareSpaceAuthLoader] = useState(false);
  // const [progress, setProgress] = useState(0);
  // const [fileList, setFileList] = useState([]);
  const notificationKey = `open${Date.now()}`;
  const load = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are syncing</p></div>;
  const loadCustomProducts = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are uploading</p></div>;


  const handleAuthorize = () => {
    // Redirect user to the authorization URL
    setSquareSpaceAuthLoader(true)
    window.location.href = authorizationUrl;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    console.log(queryParams, 'queryParams');

    const code = queryParams.get('code');
    console.log(code, 'code');
    const returnedState = queryParams.get('state');
    const returnedError = queryParams.get('error');
    // Validate the state to prevent CSRF attacks
    // error=access_denied
    if (returnedError == 'access_denied') {
      notification.error({
        message: 'Access Denied',
        className: "toast-error",
      })
      return;
    }
    if (returnedState !== process.env.REACT_APP_SQUARESPACE_STATE && code) {
      notification.error({
        message: 'State does not match',
        className: "toast-error",
      })
      console.error('State does not match');
      return;
    }

    // Proceed to exchange the code for an access token
    if (code && !validate?.payload?.message?.squarespace) {
      let query = new URL(window.location.href);

      let auth_token =
        // "Basic " +
        Buffer.from(process.env.REACT_APP_SQUARESPACE_CLIENT_ID + ":" + process.env.REACT_APP_SQUARESPACE_CLIENT_SECRET).toString("base64");
      let data = {
        code,
        redirect_uri: process.env.REACT_APP_SQUARESPACE_REDIRECT_URI
      }
      setLoading(true);
      dispatch(getSquareSpaceAccessToken(data)).then((res) => {
        console.log(res, 'res access token');

        if (res.type == "GET_SQUARE_SPACE_ACCESS_TOKEN_SUCCESS") {
          console.log(res, 'res res');

          notification.success({
            message: res?.payload?.message || "Connection Successful!",
            className: "toast-success",
          });
          dispatch(validateUser(token));

          query.searchParams.delete("code");
          query.searchParams.delete("state");
          window.history.replaceState(null, null, query);
          dispatch(getSquareSpaceDetail()).then((res) => {
            if (res.type == "GET_SQUARE_SPACE_DETAIL_SUCCESS") {
              setLoading(false);
              form.setFieldsValue({
                // shopUrl: res?.payload?.message?.shopify?.shop_name,
                apiKeySS: res?.payload?.message?.squarespace?.api_key,
                siteUrlSS: res?.payload?.message?.squarespace?.site_url,
                // token: res?.payload?.message?.shopify?.password,
                // storefrontKey:
                //   res?.payload?.message?.shopify?.storefront_access_token,
              });
              setFormState("edit");
              notification.open({
                message: 'Product Syncing...',
                // description:
                //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
                description: load,
                key: notificationKey,
                duration: 0,
              })
              dispatch(getProducts(validate?.payload?.message?.token))
                .then((res) => {
                  if (res?.type === "GET_PRODUCT_ERROR") {
                    notification.destroy({ key: notificationKey })
                    notification.error({
                      message: res?.payload?.data?.message,
                      className: "toast-error",
                    });
                  } else {
                    notification.destroy({ key: notificationKey })
                    notification.success({
                      message: res?.payload?.message,
                      className: "toast-success",
                    });
                  }
                  setSpin(false);
                })
                .catch((err) => {
                  notification.destroy({ key: notificationKey })

                  notification.error({
                    message: "Sync Failed",
                    className: "toast-error",
                  });
                  console.log(err.response, "err");
                });
            } else {
              if (res.type == "GET_SQUARE_SPACE_DETAIL_ERROR") {
                console.log(res, 'error res');

                notification.error({
                  message: res?.payload?.data?.message,
                  // description: res?.payload?.data?.scopes,
                  className: "toast-error",
                  duration: 0
                });
                setLoading(false);
              }
            }
          });
        }
        else {

          notification.error({
            // message: res?.payload?.data?.message,
            message: res?.payload?.data?.message?.status == 401 ? 'Unauthorized' + ' ' + res?.payload?.data?.message?.data : res?.payload?.data?.message,
            className: "toast-error",
            duration: 0
          });
        }
      }).catch((err) => {
        console.log(err, 'err');
        notification.error({
          message: err,
          className: "toast-error",
          duration: 0
        })
      })
      // // Make a POST request to Squarespace's token endpoint to exchange the code for an access token
      // fetch('https://login.squarespace.com/api/1/login/oauth/provider/tokens', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'Authorization': auth
      //   },
      //   body: new URLSearchParams({
      //     // client_id: process.env.REACT_APP_SQUARESPACE_CLIENT_ID,
      //     // client_secret: process.env.REACT_APP_SQUARESPACE_CLIENT_SECRET,
      //     code,
      //     redirect_uri: process.env.REACT_APP_SQUARESPACE_REDIRECT_URI,
      //     grant_type: 'authorization_code',
      //   }),
      // })
      //   .then(response => response.json())
      //   .then(data => {
      //     if (data.error) {
      //       console.log(data, 'data');

      //       // Handle API errors
      //       notification.error({
      //         message: data.error_description || 'An error occurred while exchanging the authorization code for an access token.',
      //         className: "toast-error",
      //       });
      //     } else {
      //       console.log('Access token:', data?.access_token);
      //       setIsModalOpenSquareSpace(true);
      //       setStore('squareSpace');
      //       setHandlerStore('squareSpace');
      //       form.setFieldsValue({
      //         apiKeySS: data?.access_token,
      //       });
      //       // setFormState("edit");
      //       // Save the access token for future API requests
      //     }
      //   })
      //   .catch(error => {
      //     console.error('Error exchanging code for token:', error);
      //     notification.error({
      //       message: error.message || 'An error occurred while exchanging the authorization code for an access token.',
      //       className: "toast-error",
      //     });
      //   });
      // let data = {
      //   'code': '1|k4/Xk9nBdfGISO6qPVZ7NAXebpStq2/zlgsnrEtrusU=|nrfoC8znedJPrGWV//leDs1za4D242zko9Uaa5FIWIc=',
      //   'redirect_uri': 'https://managedev.ormelive.com/account/ecommerce',
      //   'grant_type': 'authorization_code'
      // };

      // let config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: 'https://login.squarespace.com/api/1/login/oauth/provider/tokens',
      //   headers: {
      //     'Authorization': 'Basic MlNhVnVxaHlTcWY0d1pCUHByNmZZMngwTnE5NlRjeFk6MkJoYmZtUE1NcU5aYnJkdnc0T29zdGYzZU0vcFBDSVN1aW5tM0lMYnhMMD0=',
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'Cookie': 'ANONYMOUS_ID=sentinel-111e308d-30bc-4f36-825f-06d00f0e3cd3; crumb=BeJMR9h1wP70NDVhY2ZiZGNlMGJiN2I4ZTIxNDhmZTZmNGMyY2Mw'
      //   },
      //   data: data
      // };
      // axios.request(config)
      //   .then((response) => {
      //     console.log(JSON.stringify(response.data));
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  }, [location]);



  const showModal = (e) => {
    if (validate?.payload?.message?.salesforce) {
      setStore("salesForce");
      setIsModalSalesForce(true);
      setLoadingDetail(true);
      dispatch(getSalesForceDetail()).then((res) => {
        if (res.type == "GET_SALESFORCE_DETAIL_SUCCESS") {
          setLoadingDetail(false);
          form.setFieldsValue({
            host: res?.payload?.message?.salesforce?.host,
            site_id: res?.payload?.message?.salesforce?.site_id,
            api_version: res?.payload?.message?.salesforce?.api_version,
            client_id: res?.payload?.message?.salesforce?.client_id,
            client_secret: res?.payload?.message?.salesforce?.client_secret,
            am_username: res?.payload?.message?.salesforce?.am_username,
            am_password: res?.payload?.message?.salesforce?.am_password,
          });
          setFormState("edit");
          // handleClose();
        } else {
        }
      });
    } else if (validate?.payload?.message?.shopify) {
      setStore("shopify");
      setIsModalOpen(true);
      setLoadingDetail(true);
      dispatch(getShopifyDetail()).then((res) => {
        if (res.type == "GET_SHOPIFY_DETAIL_SUCCESS") {
          setLoadingDetail(false);
          form.setFieldsValue({
            shopUrl: res?.payload?.message?.shopify?.shop_name,
            apiKey: res?.payload?.message?.shopify?.api_key,
            token: res?.payload?.message?.shopify?.password,
            storefrontKey:
              res?.payload?.message?.shopify?.storefront_access_token,
          });
          setFormState("edit");
          // handleClose();
        } else {
        }
      });
    }
    else if (validate?.payload?.message?.magento) {
      setStore("magento");
      setIsModalOpenMagento(true);
      setLoadingDetail(true);
      dispatch(getMagentoDetail()).then((res) => {
        if (res.type == "GET_MAGENTO_DETAIL_SUCCESS") {
          // setLoading(false);
          setLoadingDetail(false);
          form.setFieldsValue({
            storeUrl: res?.payload?.message?.magento?.store_url,
            accessToken: res?.payload?.message?.magento?.access_token,
            consumerKey: res?.payload?.message?.magento?.consumer_key,
            consumerSecret: res?.payload?.message?.magento?.consumer_secret,
            clientSecret: res?.payload?.message?.magento?.access_token_secret,
          });
          setFormState("edit");
          // handleClose();
        } else {
          if (res.type == "GET_MAGENTO_DETAIL_ERROR") {
            setLoading(false);
          }
        }
      });
    }
    else if (validate?.payload?.message?.scoc) {
      setStore("siteCore");
      setIsModalOpenSiteCore(true);
      setLoadingDetail(true);
      dispatch(getSiteCoreDetail()).then((res) => {
        if (res.type == "GET_SITECORE_DETAIL_SUCCESS") {
          setLoadingDetail(false);
          form.setFieldsValue({
            shopUrl: res?.payload?.message?.scoc?.store_url,
            buyerId: res?.payload?.message?.scoc?.client_id_buyer,
          });
          setFormState("edit");
          // handleClose();
        } else {
        }
      });
    }
    else if (validate?.payload?.message?.squarespace) {
      setStore("squareSpace");
      setIsModalOpenSquareSpace(true);
      setLoadingDetail(true);
      dispatch(getSquareSpaceDetail()).then((res) => {
        if (res.type == "GET_SQUARE_SPACE_DETAIL_SUCCESS") {
          console.log(res?.payload?.message, 'res?.payload?.message');

          setLoadingDetail(false);
          form.setFieldsValue({
            // shopUrl: res?.payload?.message?.shopify?.shop_name,
            apiKeySS: res?.payload?.message?.squarespace?.api_key,
            siteUrlSS: res?.payload?.message?.squarespace?.site_url,
            // storefrontKey:
            //   res?.payload?.message?.shopify?.storefront_access_token,
          });
          setFormState("edit");
          // handleClose();
        } else {
        }
      });
    }
    else if (validate?.payload?.message?.customshop) {
      setStore("custom");
      setIsModalCustom(true);
      setLoadingDetail(true);
      dispatch(getCustomShopDetail()).then((res) => {
        if (res.type == "GET_CUSTOM_SHOP_SUCCESS") {
          setLoadingDetail(false);
          form.setFieldsValue({
            shopName: res?.payload?.data?.shop_name,
            domainName: res?.payload?.data?.domain_name,
            apiKeyCustom: res?.payload?.data?.api_key,
          });
          setFormState("edit");
          // handleClose();
        } else {
        }
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpenSiteCore(false);
    setIsModalOpenSquareSpace(false);
  };
  const handleCancel = () => {
    formStore.resetFields();
    form.setFieldsValue({
      shopUrl: "",
      apiKey: "",
      apiKeySS: "",
      siteUrlSS: "",
      apiKeyCustom: "",
      token: "",
      storefrontKey: "",
      buyerId: "",
      host: "",
      site_id: "",
      api_version: "v22_6",
      client_id: "",
      client_id_buyer: "",
      client_secret: "",
      accessToken: "",
      storeUrl: "",
      consumerKey: "",
      consumerSecret: "",
      clientSecret: "",
      storeName: "",
      website_id: "",
      csv_file: "",
      domainName: "",
    });
    setStore("");
    setHandlerStore("");
    setIsModalOpen(false);
    setIsModalOpenCloud(false);
    setIsModalSalesForce(false);
    setIsModalOpenSiteCore(false)
    setIsModalCustom(false);
    setIsModalCustomSecond(false);
    setIsModalOpenMagento(false);
    setIsModalOpenMagentoSecond(false);
    setIsModalOpenSquareSpace(false);
    setSaveLoading(false)
    setCSVFile()
    setExternalKey("")
    setVisible(false)
    setVisible2(false)
    form.resetFields();

  };

  const downloadCSV = (csvData) => {
    // Create a link element
    const link = document.createElement('a');
    // Set the href attribute to the path of your CSV file
    link.setAttribute('href', custom_sample);
    // Set the download attribute to specify the filename
    link.setAttribute('download', 'product_sample.csv');
    // Append the link to the document body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the document body
    document.body.removeChild(link);
  };

  const getGenerateKey = () => {
    setKeyLoading(true)
    dispatch(generateKey()).then((res) => {

      if (res.payload.success) {
        form.setFieldsValue({
          apikey: res?.payload?.api_key
        })
        setExternalKey(res?.payload?.api_key)
        setKeyLoading(false)
      }
    })
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleCancelSecondModal = () => {
    formStore.resetFields();
    form.setFieldsValue({
      website_id: ""
    });
    // setStore("");
    // setHandlerStore("");
    // setIsModalOpen(false);
    // setIsModalOpenCloud(false);
    // setIsModalSalesForce(false);
    // setIsModalOpenMagento(false);
    setIsModalOpenMagentoSecond(false);
    // setSaveLoading(false)
  };

  const { getShopifyDetails, validate, getSalesForceDetails, getMagentoDetails, getProduct, getCustomShopDetails, addCustomProducts, getSiteCoreDetails, getSquareSpaceDetails } = useSelector(
    (state) => {
      return state;
    }
  );
  const [formStore] = Form.useForm();
  console.log(getSquareSpaceDetails, 'getSquareSpaceDetails');

  const filterSourceType = (e) => {
    setStore(e);
    setHandlerStore(e);
    if (e === "shopify") {
      setIsModalOpen(true);
      setIsModalOpenCloud(false);
      setIsModalSalesForce(false);
      setIsModalOpenMagento(false);
    }
    if (e === "magento") {
      setIsModalOpen(false);
      setIsModalOpenCloud(false);
      setIsModalSalesForce(false);
      setIsModalOpenMagento(true);
    }
    if (e === "ordercloud") {
      setIsModalOpen(false);
      setIsModalOpenMagento(false);
      setIsModalSalesForce(false);
      setIsModalOpenCloud(true);
    }
    if (e === "salesForce") {
      setIsModalOpen(false);
      setIsModalOpenMagento(false);
      setIsModalOpenCloud(false);
      setIsModalSalesForce(true);
    }
    if (e === "squareSpace") {
      // setIsModalOpenSquareSpace(true);
      // setIsModalOpen(false);
      // setIsModalOpenCloud(false);
      // setIsModalSalesForce(false);
      // setIsModalOpenMagento(false);
      handleAuthorize()
    }
    if (e === "siteCore") {
      setIsModalOpen(false);
      setIsModalOpenCloud(false);
      setIsModalSalesForce(false);
      setIsModalOpenMagento(false);
      setIsModalOpenSiteCore(true)
    }
    if (e === "custom") {
      setIsModalOpen(false);
      setIsModalOpenMagento(false);
      setIsModalOpenCloud(false);
      setIsModalOpenSiteCore(false)
      setIsModalCustom(true);
    }
  };
  const selectStore = (e) => {
    // console.log(e);
    setSelectedWebsites(e)
    let filterWebsite = multiWebsites.find(obj => obj.id === e)
    setSelectedWebsites(filterWebsite);
    // console.log(selectedWebsites, 'selectedWebsites');
    form.setFieldsValue({
      website_id: e,
    })

  };
  const syncData = () => {
    Swal.fire({
      title: `Are You Sure You Want To Sync Products?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        notification.open({
          message: 'Product Syncing...',
          description: load,
          key: notificationKey,
          duration: 0,
        })
        setIsModalOpen(false);
        setIsModalOpenCloud(false);
        setIsModalSalesForce(false);
        setIsModalOpenMagento(false);
        setIsModalOpenMagentoSecond(false);
        setIsModalOpenSiteCore(false)
        setIsModalOpenSquareSpace(false);
        // setSpin(true);
        dispatch(getProducts(validate?.payload?.message?.token))
          .then((res) => {
            if (res?.type === "GET_PRODUCT_ERROR") {
              notification.destroy({ key: notificationKey })
              notification.error({
                message: res?.payload?.data?.message,
                className: "toast-error",
              });
            } else {
              notification.destroy({ key: notificationKey })
              notification.success({
                message: res?.payload?.message,
                className: "toast-success",
              });
              dispatch(validateUser(validate?.payload?.message?.token));
            }
            // setSpin(false);
          })
          .catch((err) => {
            notification.destroy({ key: notificationKey })
            notification.error({
              message: "Sync Failed",
              className: "toast-error",
            });
            console.log(err.response, "err");
          });
      }
    });
  };
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      if (store === "salesForce") {
        dispatch(postSalesForce(values)).then((res) => {
          if (res.success) {
            notification.success({
              message: "Successfully",
              className: "toast-success",
            });
            setIsModalSalesForce(false);
            setLoading(true);
            dispatch(validateUser(token));
            dispatch(getSalesForceDetail()).then((res) => {
              if (res.type == "GET_SALESFORCE_DETAIL_SUCCESS") {
                setLoading(false);
                form.setFieldsValue({
                  host: res?.payload?.message?.salesforce?.host,
                  site_id: res?.payload?.message?.salesforce?.site_id,
                  api_version: res?.payload?.message?.salesforce?.api_version,
                  client_id: res?.payload?.message?.salesforce?.client_id,
                  client_secret:
                    res?.payload?.message?.salesforce?.client_secret,
                  // am_username: res?.payload?.message?.salesforce?.am_username,
                  // am_password: res?.payload?.message?.salesforce?.am_password
                });
                setFormState("edit");
                notification.open({
                  message: 'Product Syncing...',
                  // description:
                  //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
                  description: load,
                  key: notificationKey,
                  duration: 0,
                })
                setIsModalOpenCloud(false);
                setIsModalSalesForce(false);
                setIsModalOpenMagento(false);
                setIsModalOpenMagentoSecond(false);
                dispatch(getProducts(validate?.payload?.message?.token))
                  .then((res) => {
                    if (res?.type === "GET_PRODUCT_ERROR") {
                      notification.error({
                        message: res?.payload?.data?.message,
                        className: "toast-error",
                      });
                    } else {
                      notification.destroy({ key: notificationKey })
                      notification.success({
                        message: res?.payload?.message,
                        className: "toast-success",
                      });
                    }
                    setSpin(false);
                  })
                  .catch((err) => {
                    notification.destroy({ key: notificationKey })

                    notification.error({
                      message: "Sync Failed",
                      className: "toast-error",
                    });
                    console.log(err.response, "err");
                  });
                // handleClose();
              } else {
                if (res.type == "GET_SALESFORCE_DETAIL_ERROR") {
                  setLoading(false);
                }
              }
            });
          } else {
            notification.error({
              message: res.message,
              className: "toast-error",
            });
          }
        });
      }
      else if (store === "shopify") {
        let url = values.shopUrl
        url = url.replace(/^https?:\/\//, '')
        url = url.replace(/\/+$/, '')
        let data = {
          apiKey: values.apiKey,
          password: values.token,
          // shopName: values.shopUrl,
          shopName: url,
          storefront_access_token: values.storefrontKey,
        };

        dispatch(postShopify(data)).then((res) => {
          if (res.type === "POST_SHOPIFY_SUCCESS") {
            notification.success({
              message: "Successfully",
              className: "toast-success",
            });
            setIsModalOpen(false);
            setLoading(true);
            // if(!validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify){
            dispatch(validateUser(token));
            // }
            dispatch(getShopifyDetail()).then((res) => {
              if (res.type == "GET_SHOPIFY_DETAIL_SUCCESS") {
                setLoading(false);
                form.setFieldsValue({
                  shopUrl: res?.payload?.message?.shopify?.shop_name,
                  apiKey: res?.payload?.message?.shopify?.api_key,
                  token: res?.payload?.message?.shopify?.password,
                  storefrontKey:
                    res?.payload?.message?.shopify?.storefront_access_token,
                });
                setFormState("edit");
                notification.open({
                  message: 'Product Syncing...',
                  // description:
                  //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
                  description: load,
                  key: notificationKey,
                  duration: 0,
                })
                setIsModalOpenCloud(false);
                setIsModalSalesForce(false);
                setIsModalOpenMagento(false);
                setIsModalOpenMagentoSecond(false);
                dispatch(getProducts(validate?.payload?.message?.token))
                  .then((res) => {
                    if (res?.type === "GET_PRODUCT_ERROR") {
                      notification.destroy({ key: notificationKey })
                      notification.error({
                        message: res?.payload?.data?.message,
                        className: "toast-error",
                      });
                    } else {
                      notification.destroy({ key: notificationKey })
                      notification.success({
                        message: res?.payload?.message,
                        className: "toast-success",
                      });
                    }
                    setSpin(false);
                  })
                  .catch((err) => {
                    notification.destroy({ key: notificationKey })

                    notification.error({
                      message: "Sync Failed",
                      className: "toast-error",
                    });
                    console.log(err.response, "err");
                  });
                // handleClose();
              } else {
                if (res.type == "GET_SHOPIFY_DETAIL_ERROR") {
                  notification.error({
                    message: res?.payload?.data?.message,
                    // description: res?.payload?.data?.scopes,
                    className: "toast-error",
                    duration: 0
                  });
                  setLoading(false);
                }
              }
            });
          } else {
            console.log(res, 'res');
            let error_msg = (
              <>
                {res?.payload?.data?.message}
              </>
            )
            if (res?.payload?.data?.scopes) {
              error_msg = (
                <>
                  {res?.payload?.data?.message} <br />
                  Scope Required: {res?.payload?.data?.scopes?.join()}
                </>
              )
            }
            notification.error({
              // message: res?.payload?.data?.message,
              message: error_msg,
              className: "toast-error",
            });
          }
        });
      }
      else if (store === "magento") {
        let data;
        // if (values?.website_id !== "" && values?.website_id) {

        //   let url = values.storeUrl
        //   url = url.replace(/^https?:\/\//, '')
        //   url = url.replace(/\/+$/, '')
        //   data = {
        //     store_url: url,
        //     consumer_key: values.consumerKey,
        //     consumer_secret: values.consumerSecret,
        //     access_token: values.accessToken,
        //     access_token_secret: values.clientSecret,
        //     is_multi_website: true,
        //     website_id: values?.website_id
        //   };
        //   setSaveLoading(true)

        //   dispatch(postMagento(data)).then((res) => {
        //     if (res.type === "POST_MAGENTO_SUCCESS") {
        //       let is_multi_website = Array.isArray(res?.payload?.message) && !res?.payload?.connection
        //       if (is_multi_website) {
        //         setIsModalOpenMagentoSecond(true);
        //         setMultiWebsites(res?.payload?.message)
        //       }
        //       else {
        //         setIsModalOpenMagentoSecond(false);
        //       }

        //       notification.success({
        //         message: res?.payload?.message,
        //         className: "toast-success",
        //       });
        //       setIsModalOpenMagento(false);
        //       setLoading(true);
        //       setSaveLoading(false)
        //       dispatch(validateUser(token));
        //       // }
        //       dispatch(getMagentoDetail()).then((res) => {
        //         if (res.type == "GET_MAGENTO_DETAIL_SUCCESS") {
        //           setLoading(false);
        //           setFormState("edit");
        //         } else {
        //           if (res.type == "GET_MAGENTO_DETAIL_ERROR") {
        //             setLoading(false);
        //           }
        //         }
        //       });
        //       formStore.resetFields();
        //       form.setFieldsValue({
        //         website_id: ""
        //       });
        //     } else {
        //       setSaveLoading(false)
        //       notification.error({
        //         message: res?.payload?.data?.message,
        //         className: "toast-error",
        //       });
        //     }
        //   });
        // } else {
        let url = values.storeUrl
        url = url.replace(/^https?:\/\//, '')
        url = url.replace(/\/+$/, '')
        data = {
          // store_url: url,
          // consumer_key: "6fjzyee1ah1j807ptzy8i3ie9q09w3h0",
          // consumer_secret: "663xlgb77961i7aiprydxqeinq6hzcdq",
          // access_token: "eegxcirnuc3vnp83ua6pwsk5xpiwg45f",
          // access_token_secret: "qu973rq4yj8wjfb0ltdxb5e14rzlkhsn",
          // is_multi_website: false

          store_url: url,
          consumer_key: values.consumerKey,
          consumer_secret: values.consumerSecret,
          access_token: values.accessToken,
          access_token_secret: values.clientSecret,
          // is_multi_website: false
        };

        setSaveLoading(true)

        dispatch(postMagento(data)).then((res) => {
          if (res.type === "POST_MAGENTO_SUCCESS") {

            // console.log(res, 'res');
            // let is_multi_website = Array.isArray(res?.payload?.message) && !res?.payload?.connection
            // if (is_multi_website) {
            //   setIsModalOpenMagentoSecond(true);
            //   setSaveLoading(false)
            //   setMultiWebsites(res?.payload?.message)
            // }
            // else {
            setIsModalOpenMagentoSecond(false);
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
            setIsModalOpenMagento(false);
            setLoading(true);
            setSaveLoading(false)
            dispatch(validateUser(token));
            // }
            dispatch(getMagentoDetail()).then((res) => {
              if (res.type == "GET_MAGENTO_DETAIL_SUCCESS") {
                setLoading(false);
                form.setFieldsValue({
                  storeUrl: res?.payload?.message?.magento?.store_url,
                  accessToken: res?.payload?.message?.magento?.access_token,
                });
                notification.open({
                  message: 'Product Syncing...',
                  // description:
                  //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
                  description: load,
                  key: notificationKey,
                  duration: 0,
                })
                setIsModalOpenCloud(false);
                setIsModalSalesForce(false);
                setIsModalOpenMagento(false);
                setIsModalOpenMagentoSecond(false);
                dispatch(getProducts(validate?.payload?.message?.token))
                  .then((res) => {
                    if (res?.type === "GET_PRODUCT_ERROR") {
                      notification.destroy({ key: notificationKey })
                      notification.error({
                        message: res?.payload?.data?.message,
                        className: "toast-error",
                      });
                    } else {
                      notification.destroy({ key: notificationKey })
                      notification.success({
                        message: res?.payload?.message,
                        className: "toast-success",
                      });
                    }
                    setSpin(false);
                  })
                  .catch((err) => {
                    notification.destroy({ key: notificationKey })

                    notification.error({
                      message: "Sync Failed",
                      className: "toast-error",
                    });
                    console.log(err.response, "err");
                  });
                setFormState("edit");
                // handleClose();
              } else {
                if (res.type == "GET_MAGENTO_DETAIL_ERROR") {
                  setLoading(false);
                }
              }
            });
            // }


          } else {
            setSaveLoading(false)
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          }
        });
        // }

      }
      else if (store === "squareSpace") {
        // let url = values.shopUrl
        // url = url.replace(/^https?:\/\//, '')
        // url = url.replace(/\/+$/, '')
        let data = {
          api_key: values.apiKeySS,
          // password: values.token,
          // shopName: values.shopUrl,
          // shopName: url,
          // storefront_access_token: values.storefrontKey,
        };

        dispatch(postSquareSpace(data)).then((res) => {
          if (res.type === "POST_SQUARE_SPACE_SUCCESS") {
            console.log(res, 'res success');
            notification.success({
              message: "Successfully",
              className: "toast-success",
            });
            setIsModalOpenSquareSpace(false);
            setLoading(true);
            // if(!validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify){
            dispatch(validateUser(token));
            // }
            dispatch(getSquareSpaceDetail()).then((res) => {
              if (res.type == "GET_SQUARE_SPACE_DETAIL_SUCCESS") {
                setLoading(false);
                form.setFieldsValue({
                  // shopUrl: res?.payload?.message?.shopify?.shop_name,
                  apiKeySS: res?.payload?.message?.squarespace?.api_key,
                  siteUrlSS: res?.payload?.message?.squarespace?.site_url,
                  // token: res?.payload?.message?.shopify?.password,
                  // storefrontKey:
                  //   res?.payload?.message?.shopify?.storefront_access_token,
                });
                setFormState("edit");
                notification.open({
                  message: 'Product Syncing...',
                  // description:
                  //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
                  description: load,
                  key: notificationKey,
                  duration: 0,
                })
                setIsModalOpenCloud(false);
                setIsModalSalesForce(false);
                setIsModalOpenMagento(false);
                setIsModalOpenMagentoSecond(false);
                dispatch(getProducts(validate?.payload?.message?.token))
                  .then((res) => {
                    if (res?.type === "GET_PRODUCT_ERROR") {
                      notification.destroy({ key: notificationKey })
                      notification.error({
                        message: res?.payload?.data?.message,
                        className: "toast-error",
                      });
                    } else {
                      notification.destroy({ key: notificationKey })
                      notification.success({
                        message: res?.payload?.message,
                        className: "toast-success",
                      });
                    }
                    setSpin(false);
                  })
                  .catch((err) => {
                    notification.destroy({ key: notificationKey })

                    notification.error({
                      message: "Sync Failed",
                      className: "toast-error",
                    });
                    console.log(err.response, "err");
                  });
                // handleClose();
              } else {
                if (res.type == "GET_SQUARE_SPACE_DETAIL_ERROR") {
                  notification.error({
                    message: res?.payload?.data?.message,
                    // description: res?.payload?.data?.scopes,
                    className: "toast-error",
                    duration: 0
                  });
                  setLoading(false);
                }
              }
            });
          } else {
            console.log(res, 'res error');
            let error_msg = (
              <>
                {res?.payload?.data?.message}
              </>
            )
            if (res?.payload?.data?.scopes) {
              error_msg = (
                <>
                  {res?.payload?.data?.message} <br />
                  Scope Required: {res?.payload?.data?.scopes?.join()}
                </>
              )
            }
            notification.error({
              // message: res?.payload?.data?.message,
              message: error_msg,
              className: "toast-error",
              duration: 10
            });
          }
        });
      }
      else if (store === "siteCore") {
        let url = values.shopUrl
        url = url.replace(/^https?:\/\//, '')
        url = url.replace(/\/+$/, '')
        console.log(url);
        let data = {
          // client_id: 'C7FFF2C3-4BE1-4BB4-954F-5D57A6038276',
          // store_url: 'sandboxapi.ordercloud.io',
          client_id_buyer: values.buyerId,
          store_url: url,
        };
        setSiteCoreBtn(true)
        dispatch(postSiteCore(data)).then((res) => {
          setSiteCoreBtn(false)
          if (res.type === "POST_SITECORE_SUCCESS") {
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
            setIsModalOpenSiteCore(false);
            setLoading(true);
            // if(!validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify){
            dispatch(validateUser(token));
            // }
            dispatch(getSiteCoreDetail()).then((res) => {
              if (res.type == "GET_SITECORE_DETAIL_SUCCESS") {
                setLoading(false);
                form.setFieldsValue({
                  shopUrl: res?.payload?.message?.scoc?.store_url,
                  buyerId: res?.payload?.message?.scoc?.client_id_buyer,
                  // token: res?.payload?.message?.shopify?.password,
                  // storefrontKey:
                  //   res?.payload?.message?.shopify?.storefront_access_token,
                });
                setFormState("edit");
                notification.open({
                  message: 'Product Syncing...',
                  description: load,
                  key: notificationKey,
                  duration: 0,
                })
                setIsModalOpenCloud(false);
                setIsModalSalesForce(false);
                setIsModalOpenMagento(false);
                setIsModalOpenMagentoSecond(false);
                dispatch(getProducts(validate?.payload?.message?.token))
                  .then((res) => {
                    if (res?.type === "GET_PRODUCT_ERROR") {
                      notification.destroy({ key: notificationKey })
                      notification.error({
                        message: res?.payload?.data?.message,
                        className: "toast-error",
                      });
                    } else {
                      notification.destroy({ key: notificationKey })
                      notification.success({
                        message: res?.payload?.message,
                        className: "toast-success",
                      });
                    }
                    setSpin(false);
                  })
                  .catch((err) => {
                    notification.destroy({ key: notificationKey })

                    notification.error({
                      message: "Sync Failed",
                      className: "toast-error",
                    });
                    console.log(err.response, "err");
                  });
                // handleClose();
              } else {
                if (res.type == "GET_SITECORE_DETAIL_ERROR") {
                  setLoading(false);
                }
              }
            });
          } else {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
            // setIsModalOpenSiteCore(false);
          }
        });
      }
      else if (store === "custom") {
        let url = values.domainName
        url = url.replace(/^https?:\/\//, '')
        url = url.replace(/\/+$/, '')
        setSaveLoading(true)
        let data = {
          shop_name: validate?.payload?.message?.website_address,
          domain_name: url,
          api_key: values.apiKeyCustom,
        }
        dispatch(postCustomShop(data)).then((res) => {
          if (res.payload.success) {
            notification.success({
              message: "Successfully",
              className: "toast-success",
            });
            setIsModalCustom(false);
            setLoading(true);
            setSaveLoading(false)
            dispatch(validateUser(token));
            dispatch(getCustomShopDetail()).then((res) => {
              setLoading(false);
            })


          }
          else {
            notification.error({
              message: "Failed",
              className: "toast-error",
            });
            setLoading(false);
          }
        })
      }
    },
  });


  useEffect(() => {
    // setLoading(true);
    if (validate?.payload?.message?.salesforce) {
      dispatch(getSalesForceDetail()).then((res) => {
        if (res.type == "GET_SALESFORCE_DETAIL_SUCCESS") {
          setLoading(false);
          form.setFieldsValue({
            host: res?.payload?.message?.salesforce?.host,
            site_id: res?.payload?.message?.salesforce?.site_id,
            api_version: res?.payload?.message?.salesforce?.api_version,
            client_id: res?.payload?.message?.salesforce?.client_id,
            client_secret: res?.payload?.message?.salesforce?.client_secret,
            // am_username: res?.payload?.message?.salesforce?.am_username,
            // am_password: res?.payload?.message?.salesforce?.am_password
          });
          setFormState("edit");
          // handleClose();
        } else {
          if (res.type == "GET_SALESFORCE_DETAIL_ERROR") {
            setLoading(false);
          }
        }
      });
    } else if (validate?.payload?.message?.shopify) {
      dispatch(getShopifyDetail()).then((res) => {
        if (res.type == "GET_SHOPIFY_DETAIL_SUCCESS") {
          setLoading(false);
          form.setFieldsValue({
            shopUrl: res?.payload?.message?.shopify?.shop_name,
            apiKey: res?.payload?.message?.shopify?.api_key,
            token: res?.payload?.message?.shopify?.password,
            storefrontKey:
              res?.payload?.message?.shopify?.storefront_access_token,
          });
          setFormState("edit");
          // handleClose();
        } else {
          if (res.type == "GET_SHOPIFY_DETAIL_ERROR") {
            notification.error({
              message: res?.payload?.data?.message,
              // description: res?.payload?.data?.scopes,
              className: "toast-error",
              duration: 0
            });
            setLoading(false);
          }
        }
      });
    }
    else if (validate?.payload?.message?.magento) {
      dispatch(getMagentoDetail()).then((res) => {
        if (res.type == "GET_MAGENTO_DETAIL_SUCCESS") {
          setLoading(false);
          form.setFieldsValue({
            storeUrl: res?.payload?.message?.magento?.store_url,
            accessToken: res?.payload?.message?.magento?.access_token,
          });
          setFormState("edit");
          // handleClose();
        } else {
          if (res.type == "GET_MAGENTO_DETAIL_ERROR") {
            setLoading(false);
          }
        }
      });
    }
    else if (validate?.payload?.message?.scoc) {
      dispatch(getSiteCoreDetail()).then((res) => {
        if (res.type == "GET_SITECORE_DETAIL_SUCCESS") {
          setLoading(false);
          form.setFieldsValue({
            shopUrl: res?.payload?.message?.scoc?.store_url,
            buyerId: res?.payload?.message?.scoc?.client_id_buyer,
          });
          setFormState("edit");
          // handleClose();
        } else {
          if (res.type == "GET_SITECORE_DETAIL_ERROR") {
            setLoading(false);
          }
        }
      });
    }
    else if (validate?.payload?.message?.squarespace) {
      dispatch(getSquareSpaceDetail()).then((res) => {
        if (res.type == "GET_SQUARE_SPACE_DETAIL_SUCCESS") {
          setLoading(false);
          form.setFieldsValue({
            apiKeySS: res?.payload?.message?.squarespace?.api_key,
            siteUrlSS: res?.payload?.message?.squarespace?.site_url,
          });
          setFormState("edit");
          // handleClose();
        } else {
          if (res.type == "GET_SQUARE_SPACE_DETAIL_ERROR") {
            notification.error({
              message: res?.payload?.data?.message,
              // description: res?.payload?.data?.scopes,
              className: "toast-error",
              duration: 0
            });
            setLoading(false);
          }
        }
      });
    }
    else if (validate?.payload?.message?.customshop) {
      dispatch(getCustomShopDetail()).then((res) => {
        if (res.payload.success) {
          setLoading(false);
          form.setFieldsValue({
            shopName: res?.payload?.data?.shop_name,
            domainName: res?.payload?.data?.domain_name,
            apiKeyCustom: res?.payload?.data?.api_key,
          });
          setFormState("edit");
        }

      })
    }
    else {
      setLoading(false);
    }
  }, []);

  const uploadFileDragger = {
    accept: "text/csv",
    name: 'file',
    multiple: false,
    maxCount: 1,
    listType: "picture",
    action: "https://httpbin.org/post",
    beforeUpload: (file) => {
      const isPNG =
        file.type === "text/csv";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setCSVFile(info?.file?.originFileObj)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);

    },
    // progress: {
    //   strokeColor: {
    //     '0%': '#108ee9',
    //     '100%': '#87d068',
    //   },
    //   strokeWidth: 3,
    //   format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    // },

    onRemove(e) {
      setCSVFile()
      // setFileList([])
    },
    // fileList,
    // customRequest: (options) => {
    //   const { file, onSuccess, onError, onProgress } = options;

    //   const formData = new FormData();
    //   formData.append('file', file);

    //   const xhr = new XMLHttpRequest();

    //   xhr.upload.onprogress = (event) => {
    //     if (event.lengthComputable) {
    //       const percent = Math.round((event.loaded / event.total) * 100);
    //       setProgress(percent);
    //       onProgress({ percent: (event.loaded / event.total) * 100 });
    //     }
    //   };

    //   xhr.onload = () => {

    //     if (xhr.status === 200) {
    //       onSuccess(xhr.response);
    //       console.log(xhr, 'xhr');
    //       message.success(`${file.name} file uploaded successfully`);
    //       setProgress(0); // Reset progress
    //       setFileList(currentList => [...currentList, {
    //         uid: file.uid,
    //         name: file.name,
    //         status: 'done',
    //         url: 'https://httpbin.org/post', // You might need to adjust this according to your response
    //       }]);
    //     } else {
    //       onError(new Error('Upload failed'));
    //     }
    //     console.log(xhr, 'xhr2');
    //   };

    //   xhr.onerror = () => {
    //     onError(new Error('Upload failed'));
    //   };

    //   xhr.open('POST', 'https://httpbin.org/post', true);
    //   xhr.send(formData);
    // },
  };
  // console.log(CSVFile, 'csv');
  const copyUrl = (key) => {
    navigator.clipboard.writeText(key);
    notification.success({
      message: 'Api Key Copied!',
      className: 'toast-success',
    });
  };


  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const toggleVisibility2 = () => {
    setVisible2(!visible2);
  };

  const copyToClipboard = (value, text) => {
    console.log(value, 'value');

    navigator.clipboard.writeText(value).then(() => {
      notification.success({
        message: `${text} Copied!`,
        className: 'toast-success',
      });
      console.log('Password copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleUpload = () => {
    setIsModalCustomSecond(false)
    notification.open({
      message: 'Product Uploading...',
      description: loadCustomProducts,
      key: notificationKey,
      duration: 0,
    })
    let formData = new FormData();
    // console.log(CSVFile, 'CSVFile');

    formData.append("file", CSVFile)
    dispatch(addProduct(formData)).then((res) => {
      // console.log(res, 'resss');
      if (res.payload.success) {
        notification.destroy({ key: notificationKey })
        notification.success({
          message: res.payload.message,
          className: "toast-success",
        });
      }
      else {
        notification.destroy({ key: notificationKey })
        notification.error({
          message: res.payload.message || "Something went wrong",
          className: "toast-error",
        });
      }
      form.resetFields();
    })
  }

  const formList = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
            disabled
          >
            <Option value="shopify">Shopify</Option>
            {/* <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option> */}
          </Select>
        </div>

        <div className="col-6 d-flex align-items-center justify-content-end"
        >
          {getShopifyDetails?.payload?.connection && validate?.payload?.message?.shopify ?
            getProduct && getProduct?.loading ?
              <div className="d-flex"><span className="spin-color mx-2">
                <Spin size="small" />
              </span><p>Syncing</p></div>
              :
              (
                <Link
                  to="#"
                  className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                  onClick={() => syncData()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                  {/* <Spin spinning={spinner}> */}

                  <span className="nav-text">Sync</span>
                  {/* </Spin> */}
                </Link>
              ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}
          {/* {getShopifyDetails?.payload?.message?.shopify ? (
            <>
              {getShopifyDetails?.payload?.connection ? (
                <span className="connection-status-badge green-status">
                  Connected
                </span>
              ) : (
                <span className="connection-status-badge red-status">
                  Not Connected
                </span>
              )}
            </>
          ) : null} */}
        </div>
      </div>
      <Form.Item
        name="shopUrl"
        labelCol={{ span: 24 }}
        // label="Enter Shop Url"
        label={
          <>
            Enter Shop Url
            <div style={{ fontSize: '12px', marginLeft: '5px' }}>(Example: your-store-name.myshopify.com)</div>
          </>
        }
        initialValue={formState === "add" ? null : form.getFieldValue().shopUrl}
        rules={[
          {
            required: true,
            message: "Please Enter Shop Url",
          },
          {
            pattern: new RegExp(/^(?!https:\/\/).*/),
            message: "https:// not allowed",
          },
          {
            pattern: new RegExp(/^(?!http:\/\/).*/),
            message: "http:// not allowed",
          },
          // {
          //   // type: 'url',
          //   pattern: (/^(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/),
          //   message: 'Enter valid url!',
          // },
          {
            // type: 'url',
            pattern: /^(www\.)?([\da-zA-Z-]+)\.myshopify\.com$/,
            message: 'Enter valid url! Example: your-store-name.myshopify.com',
          }
        ]}
      >
        <Input
          placeholder="Enter Shop Url"
          //value={"test.myshopify.com"}
          size="large"
          // onChange={onChange}
          addonBefore="https://"
          // addonAfter=".com"
          readOnly={formState == "add" ? false : true}
        />
      </Form.Item>
      <Form.Item
        name="apiKey"
        labelCol={{ span: 24 }}
        // label="Enter API Key"
        label={
          <>
            Enter API Key
            <Popover
              // content={content(record)}
              content={<>
                <div className="mb-2">
                  Follow below steps in your Shopify Admin panel to get API keys:<br />
                  1. Log in to your Shopify Admin panel.<br />
                  2. Click <strong>Apps {'>'} App and sales channel settings.</strong><br />
                  3. Click on <strong>Develop apps</strong> at the top right.<br />
                  4. Select the app you created for ORME.<br />
                  5. Go to <strong>API credentials</strong> and Copy <strong>API Key</strong>.
                </div>
                <img src={admin_api_key} width={700} />
              </>}
              title="Instructions"
              trigger="click"
              // open={open && index === selectIndex}
              // onOpenChange={() => { handleOpenChange(); setSelectIndex(index) }}
              placement="right"
            >
              <FontAwesomeIcon
                style={{ color: '#052977' }}
                className="cursor-pointer ml-2"
                icon={faInfoCircle}
                // onClick={() => setInfoModal(true)}
                title="Instructions"
              />
            </Popover>
          </>
        }
        initialValue={formState === "add" ? null : form.getFieldValue().apiKey}
        rules={[
          {
            required: true,
            message: "Please Enter API Key",
          },
        ]}
      >
        <Input
          autoComplete="new-password"
          placeholder="Enter API Key"
          //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
          size="large"
          onChange={(e) => setPassword(e.target.value)}
          readOnly={formState === "add" ? false : true}
          // onChange={onChange}
          suffix={
            <Tooltip title="Copy API Key">
              {formState !== 'add' && <Button disabled={formState === 'add'} className="width20" type="text" icon={
                <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().apiKey, 'API Key')} />
              }>
              </Button>}
            </Tooltip>
          }
        />
      </Form.Item>
      <Form.Item
        name="token"
        labelCol={{ span: 24 }}
        // label="Enter Admin API Access Token"
        label={
          <>
            Enter Admin API Access Token
            <Popover
              // content={content(record)}
              content={<>
                <div className="mb-2">
                  Follow below steps in your Shopify Admin panel to get Admin API access token:<br />
                  1. Log in to your Shopify Admin panel.<br />
                  2. Click <strong>Apps {'>'} App and sales channel settings.</strong><br />
                  3. Click on <strong>Develop apps</strong> at the top right.<br />
                  4. Select the app you created for ORME.<br />
                  5. Go to <strong>API credentials</strong> and Copy <strong>Admin API access token</strong>.
                </div>
                <img src={admin_api_token} width={700} />
              </>}
              title="Instructions"
              trigger="click"
              // open={open && index === selectIndex}
              // onOpenChange={() => { handleOpenChange(); setSelectIndex(index) }}
              placement="right"
            >
              <FontAwesomeIcon
                style={{ color: '#052977' }}
                className="cursor-pointer ml-2"
                icon={faInfoCircle}
                // onClick={() => setInfoModal(true)}
                title="Instructions"
              />
            </Popover>
          </>
        }
        disabled={formState === "add" ? false : true}
        initialValue={formState === "add" ? null : form.getFieldValue().token}
        rules={[
          {
            required: true,
            message: "Please Enter Admin API Access Token",
          },
        ]}
      >
        <Input
          readOnly={formState === "add" ? false : true}
          type={visible ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Enter Admin API Access Token"
          //value={"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"}
          size="large"
          // onChange={onChange}
          onChange={(e) => setPassword(e.target.value)}
          suffix={
            <span className="d-flex align-items-center">
              <Tooltip title="">
                {visible ? (
                  <EyeTwoTone onClick={toggleVisibility} style={{ marginRight: 10 }} />
                ) : (
                  <EyeInvisibleOutlined onClick={toggleVisibility} style={{ marginRight: 10 }} />
                )}
              </Tooltip>
              {formState !== 'add' && <Tooltip title="Copy Admin API Access Token">
                <Button disabled={formState === 'add'} className="width20" type="text" icon={
                  <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().token, 'Admin API Access Token')} />
                }>
                </Button>
              </Tooltip>}
            </span>
          }
        />
      </Form.Item>
      {/* <Form.Item
        name="storefrontKey"
        labelCol={{ span: 24 }}
        label="Enter StoreFront Access Token"
        initialValue={
          formState === "add" ? null : form.getFieldValue().storefrontKey
        }
        rules={[
          {
            required: true,
            message: "Please Enter StoreFront Access Token",
          },
        ]}
      >
        <Input.Password
          placeholder="Enter StoreFront Access Token"
          //value={"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"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item> */}
      <div className="d-flex justify-content-end">
        {
          formState === 'add' &&
          <Button
            disabled={getProduct && getProduct?.loading}
            // to="#"
            className="default-btn d-inline-flex align-items-center justify-content-center"
            onClick={submit}
          >
            <span className="nav-text">Save</span>
          </Button>}
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const formSalesForce = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
            disabled
          >
            <Option value="salesForce">SalesForce</Option>
            {/* <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option> */}
          </Select>
        </div>

        <div className="col-6 d-flex align-items-center justify-content-end">
          {getSalesForceDetails?.payload?.connection ?
            getProduct && getProduct?.loading ?
              <div className="d-flex"><span className="spin-color mx-2">
                <Spin size="small" />
              </span><p>Syncing</p></div>
              :
              (
                <Link
                  disabled={getProduct && getProduct?.loading}
                  to="#"
                  className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                  onClick={() => syncData()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                  {/* <Spin spinning={spinner}> */}

                  <span className="nav-text">Sync</span>
                  {/* </Spin> */}
                </Link>
              ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}
          {/* {getShopifyDetails?.payload?.message?.shopify ? (
            <>
              {getShopifyDetails?.payload?.connection ? (
                <span className="connection-status-badge green-status">
                  Connected
                </span>
              ) : (
                <span className="connection-status-badge red-status">
                  Not Connected
                </span>
              )}
            </>
          ) : null} */}
        </div>
      </div>
      <Form.Item
        name="host"
        labelCol={{ span: 24 }}
        label="Instance Host"
        initialValue={formState === "add" ? null : form.getFieldValue().host}
        rules={[
          {
            required: true,
            message: "Please Provide Instance Host",
          },
          {
            pattern: new RegExp(/^(?!https:\/\/).*/),
            message: "https:// not allowed",
          },
          {
            pattern: new RegExp(/^(?!http:\/\/).*/),
            message: "http:// not allowed",
          },
          {
            // type: 'url',
            pattern: (/^(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/),
            message: 'Enter valid url!',
          },
        ]}
      >
        <Input
          addonBefore="https://"
          placeholder="Shop URL"
          readOnly={formState === "add" ? false : true}
          //value={"test.myshopify.com"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item>

      <Form.Item
        name="api_version"
        labelCol={{ span: 24 }}
        label="API Version"
        initialValue={formState === "add" ? "v22_6" : "v22_6"}
      >
        <Input
          placeholder="API Version"
          value={"v22_6"}
          size="large"
          disabled={true}
        // onChange={onChange}
        />
      </Form.Item>

      <Form.Item
        name="site_id"
        labelCol={{ span: 24 }}
        label="Site ID"
        initialValue={formState === "add" ? null : form.getFieldValue().site_id}
        rules={[
          {
            required: true,
            message: "Please Enter Site ID",
          },
        ]}
      >
        <Input
          autoComplete="new-password"
          placeholder="Site ID "
          //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
          size="large"
          // onChange={onChange}
          readOnly={formState === "add" ? false : true}
          suffix={
            <span className="d-flex">
              <Tooltip title="Copy site id">
                {formState !== 'add' && <Button disabled={formState === 'add'} className="width20" type="text" icon={
                  <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().site_id, 'Site ID')} />
                }>
                </Button>}
              </Tooltip>
            </span>
          }
        />
      </Form.Item>

      <Form.Item
        name="client_id"
        labelCol={{ span: 24 }}
        label="Client ID"
        initialValue={
          formState === "add" ? null : form.getFieldValue().client_id
        }
        rules={[
          {
            required: true,
            message: "Please Enter Client ID",
          },
        ]}
      >
        <Input
          type={visible ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Client ID"
          readOnly={formState === "add" ? false : true}
          //value={"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"}
          size="large"
          // onChange={onChange}
          onChange={(e) => setPassword(e.target.value)}
          suffix={
            <span className="d-flex align-items-center">
              <Tooltip title="">
                {visible ? (
                  <EyeTwoTone onClick={toggleVisibility} style={{ marginRight: 10 }} />
                ) : (
                  <EyeInvisibleOutlined onClick={toggleVisibility} style={{ marginRight: 10 }} />
                )}
              </Tooltip>
              {formState !== 'add' && <Tooltip title="Copy client id">
                <Button disabled={formState === 'add'} className="width20" type="text" icon={
                  <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().client_id, 'Client ID')} />
                }>
                </Button>
              </Tooltip>}
            </span>
          }
        />
      </Form.Item>

      <Form.Item
        name="client_secret"
        labelCol={{ span: 24 }}
        label="Client Secret"
        initialValue={
          formState === "add" ? null : form.getFieldValue().client_secret
        }
        rules={[
          {
            required: true,
            message: "Please Enter Client Secret key",
          },
        ]}
      >
        <Input
          type={visible2 ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Client Secret"
          readOnly={formState === "add" ? false : true}
          //value={"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"}
          size="large"
          // onChange={onChange}
          onChange={(e) => setPassword(e.target.value)}
          suffix={
            <span className="d-flex align-items-center">
              <Tooltip title="">
                {visible2 ? (
                  <EyeTwoTone onClick={toggleVisibility2} style={{ marginRight: 10 }} />
                ) : (
                  <EyeInvisibleOutlined onClick={toggleVisibility2} style={{ marginRight: 10 }} />
                )}
              </Tooltip>
              {formState !== 'add' && <Tooltip title="Copy client secret key">
                <Button disabled={formState === 'add'} className="width20" type="text" icon={
                  <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().client_secret, 'Client Secret Key')} />
                }>
                </Button>
              </Tooltip>}
            </span>
          }
        />
      </Form.Item>

      {/* <Form.Item
        name="am_username"
        labelCol={{ span: 24 }}
        label="AM Username"
        initialValue={formState === "add" ? null : form.getFieldValue().am_username}
        rules={[
          {
            required: true,
            message: "Please Enter AM Username",
          },
        ]}
      >
        <Input
          placeholder="AM Username"
          //value={"test.myshopify.com"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item>

      <Form.Item
        name="am_password"
        labelCol={{ span: 24 }}
        label="AM password"
        initialValue={formState === "add" ? null : form.getFieldValue().am_password}
        rules={[
          {
            required: true,
            message: "Please Enter AM password",
          },
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          placeholder="AM password"
          //value={"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"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item> */}

      <div className="d-flex justify-content-end">
        {formState == 'add' && <Button
          disabled={getProduct && getProduct?.loading}
          // to="#"
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
        >
          <span className="nav-text">Save</span>
        </Button>}
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const formOracle = [
    <div layout="vertical">
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <div className="row d-fle">
            <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch d-flex justify-content-between flex-column">
              <h4 className="rounded">Oracle Setup</h4>
              <div className="text-center d-none d-lg-block">
                <img src={oracleLogo} alt="oracleLogo" />
              </div>
              <div></div>
            </div>
            <div
              className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling"
              style={{ height: 493 }}
            ></div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const formMagento = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
            disabled
          >
            <Option value="magento">Magento</Option>
          </Select>
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end">
          {getMagentoDetails?.payload?.connection && validate?.payload?.message?.magento ?
            getProduct && getProduct?.loading ?
              <div className="d-flex"><span className="spin-color mx-2">
                <Spin size="small" />
              </span><p>Syncing</p></div>
              :
              (
                <Link
                  to="#"
                  className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                  onClick={() => syncData()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                  {/* <Spin spinning={spinner}> */}

                  <span className="nav-text">Sync</span>
                  {/* </Spin> */}
                </Link>
              ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}
        </div>
      </div>
      <Form.Item
        name="storeUrl"
        labelCol={{ span: 24 }}
        label="Enter Store Url"
        initialValue={formState === "add" ? null : form.getFieldValue().storeUrl}
        rules={[
          {
            required: true,
            message: "Please Enter Store Url",
          },
          {
            pattern: new RegExp(/^(?!https:\/\/).*/),
            message: "https:// not allowed",
          },
          {
            pattern: new RegExp(/^(?!http:\/\/).*/),
            message: "http:// not allowed",
          },
          {
            // type: 'url',
            pattern: (/^(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/),
            message: 'Enter valid url!',
          },
        ]}
      >
        <Input
          addonBefore="https://"
          placeholder="Enter Store Url"
          size="large"
          readOnly={formState === "add" ? false : true}
        />
      </Form.Item>


      <Form.Item
        name="consumerKey"
        labelCol={{ span: 24 }}
        label="Consumer Key"
        initialValue={formState === "add" ? null : form.getFieldValue().consumerKey}
        rules={[
          {
            required: true,
            message: "Please Enter Consumer Key",
          },
        ]}
      >
        <Input
          autoComplete="new-password"
          placeholder="Consumer Key"
          readOnly={formState === "add" ? false : true}
          //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
          size="large"
          // onChange={onChange}
          suffix={
            <Tooltip title="Copy consumer key" >
              {formState !== 'add' && <Button disabled={formState === 'add'} className="width20" type="text" icon={
                <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().consumerKey, 'Consumer Key')} />
              }>
              </Button>}
            </Tooltip>
          }
        />
      </Form.Item>


      <Form.Item
        name="consumerSecret"
        labelCol={{ span: 24 }}
        label="Consumer Secret"
        initialValue={
          formState === "add" ? null : form.getFieldValue().consumerSecret
        }
        rules={[
          {
            required: true,
            message: "Please Enter Consumer Secret",
          },
        ]}
      >
        <Input
          type={visible ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Consumer Secret"
          readOnly={formState === "add" ? false : true}
          //value={"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"}
          size="large"
          // onChange={onChange}
          onChange={(e) => setPassword(e.target.value)}
          suffix={
            <span className="d-flex align-items-center">
              <Tooltip title="">
                {visible ? (
                  <EyeTwoTone onClick={toggleVisibility} style={{ marginRight: 10 }} />
                ) : (
                  <EyeInvisibleOutlined onClick={toggleVisibility} style={{ marginRight: 10 }} />
                )}
              </Tooltip>
              {formState !== 'add' && <Tooltip title="Copy consumer secret">
                <Button disabled={formState === 'add'} className="width20" type="text" icon={
                  <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().consumerSecret, 'Consumer Secret')} />
                }>
                </Button>
              </Tooltip>}
            </span>
          }
        />
      </Form.Item>

      <Form.Item
        name="accessToken"
        labelCol={{ span: 24 }}
        label="Enter Access Token"
        initialValue={formState === "add" ? null : form.getFieldValue().accessToken}
        rules={[
          {
            required: true,
            message: "Please Enter Access Token",
          },
        ]}
      >
        <Input
          // autoComplete="new-password"
          placeholder="Enter Access Token"
          size="large"
          readOnly={formState === "add" ? false : true}
          suffix={
            <Tooltip title="Copy access token">
              {formState !== 'add' && <Button disabled={formState === 'add'} className="width20" type="text" icon={
                <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().accessToken, 'Access Token')} />
              }>
              </Button>}
            </Tooltip>
          }
        />
      </Form.Item>

      <Form.Item
        name="clientSecret"
        labelCol={{ span: 24 }}
        label="Access Token Secret"
        initialValue={
          formState === "add" ? null : form.getFieldValue().clientSecret
        }
        rules={[
          {
            required: true,
            message: "Please Enter Access Token Secret",
          },
        ]}
      >
        <Input
          type={visible2 ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Access Token Secret"
          readOnly={formState === "add" ? false : true}
          //value={"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"}
          size="large"
          // onChange={onChange}
          onChange={(e) => setPassword(e.target.value)}
          suffix={
            <span className="d-flex align-items-center">
              <Tooltip title="">
                {visible2 ? (
                  <EyeTwoTone onClick={toggleVisibility2} style={{ marginRight: 10 }} />
                ) : (
                  <EyeInvisibleOutlined onClick={toggleVisibility2} style={{ marginRight: 10 }} />
                )}
              </Tooltip>
              {formState !== 'add' && <Tooltip title="Copy access token secret">
                <Button disabled={formState === 'add'} className="width20" type="text" icon={
                  <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().clientSecret, 'Access Token Secret')} />
                }>
                </Button>
              </Tooltip>}
            </span>
          }
        />
      </Form.Item>
      {/* {getMagentoDetails?.payload?.message?.magento &&
        <div className="table-container inventory-table mb-10">
          <table className="transactions-box table">
            <thead>
              <tr>
                <th className="width-25 border-bottom-0">Website</th>
                <th className="width-25 border-bottom-0">Store</th>
                <th className="width-25 border-bottom-0">Store View</th>
              </tr>
            </thead>
          </table>
          <div className="table-responsive scrollbar-style aff-payment">
            <table className="transactions-box table">
              <tbody>
                <tr>
                  <td className="width-25 border-bottom-0">
                    {getMagentoDetails?.payload?.message?.magento?.website?.name}
                    <br />
                    (Code: {getMagentoDetails?.payload?.message?.magento?.website?.code})
                  </td>
                  <td className="width-25 border-bottom-0">
                    {getMagentoDetails?.payload?.message?.magento?.store_group?.name}
                    <br />
                    (Code: {getMagentoDetails?.payload?.message?.magento?.store_group?.code})
                  </td>
                  <td className="width-25 border-bottom-0">
                    {getMagentoDetails?.payload?.message?.magento?.store_view?.name}
                    <br />
                    (Code: {getMagentoDetails?.payload?.message?.magento?.store_view?.code})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      } */}
      <div className="d-flex justify-content-end">
        {formState == 'add' && <Button
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
          loading={saveLoading}
          disabled={saveLoading || (getProduct && getProduct?.loading)}
        >
          <span className="nav-text">Save</span>
        </Button>}
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];


  const formMagentoSecond = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-12 mb-20">

          <p className="mbpx-5">Select Website</p>
          <Select
            className="select-style dark-field w-100 mb-10"
            placeholder="Select Website"
            size="large"
            focus={false}
            onBlur={false}
            value={form.getFieldValue().website_id !== "" ? form.getFieldValue().website_id : null}
            // onChange={handleGroupBy}
            onChange={selectStore}
            required
          // disabled
          >
            {multiWebsites.length > 0 && multiWebsites?.map((item, i) => {
              return (
                <Option value={item?.id}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
          {/* {
            form.getFieldValue().website_id && form.getFieldValue().website_id !== "" &&
            <>
              <div className="table-container inventory-table">
                <table class={`table`}>
                  <thead>
                    <tr>
                      <th className="width-25 border-bottom-0">Id</th>
                      <th className="width-25 border-bottom-0">Code</th>
                      <th className="width-25 border-bottom-0">Website</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="table-body scrollbar-style">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="width-25 border-bottom-0">
                        {form.getFieldValue().website_id}
                      </td>
                      <td className="width-25 border-bottom-0">
                        {multiWebsites.find(obj => obj.id === form.getFieldValue().website_id)?.code}
                      </td>
                      <td className="width-25 border-bottom-0">
                        {multiWebsites.find(obj => obj.id === form.getFieldValue().website_id)?.name}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </>

          } */}
        </div>
      </div>
      {/* <Form.Item
        name="storeUrl"
        labelCol={{ span: 24 }}
        label="Enter Store Url"
        initialValue={formState === "add" ? null : form.getFieldValue().storeUrl}
        rules={[
          {
            required: true,
            message: "Please Enter Store Url",
          },
        ]}
      >
        <Input
          placeholder="Enter Store Url"
          size="large"
        />
      </Form.Item> */}

      <div className="d-flex justify-content-end">
        <Button
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
          loading={saveLoading}
          disabled={saveLoading || (!form.getFieldValue().website_id || form.getFieldValue().website_id === "")}
        >
          <span className="nav-text">Save</span>
        </Button>
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancelSecondModal}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const formOrdercloud = [
    <div>
      <div className="create-event-wrapper  mb-30">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
          >
            <Option value="shopify">Shopify</Option>
            <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option>
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between flex-column mb-50">
          <div className="text-center d-none d-lg-block">
            <img
              src={ordercloudLogo}
              alt="ordercloudLogo"
              className="min-w-200"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>,
  ];

  const formSquareSpace = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
            disabled
          >
            <Option value="squareSpace">Square Space</Option>
            {/* <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option> */}
          </Select>
        </div>

        <div className="col-6 d-flex align-items-center justify-content-end"
        >
          {getSquareSpaceDetails?.payload?.connection && validate?.payload?.message?.squarespace ?
            getProduct && getProduct?.loading ?
              <div className="d-flex"><span className="spin-color mx-2">
                <Spin size="small" />
              </span><p>Syncing</p></div>
              :
              (
                <Link
                  to="#"
                  className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                  onClick={() => syncData()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                  {/* <Spin spinning={spinner}> */}

                  <span className="nav-text">Sync</span>
                  {/* </Spin> */}
                </Link>
              ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}
        </div>
      </div>
      <Form.Item
        name="siteUrlSS"
        labelCol={{ span: 24 }}
        // label="Enter API Key"
        label={
          <>
            Site Url
            {/* <Popover
              // content={content(record)}
              content={<>
                <div className="mb-2">
                  Follow the steps below to generate an API key for a custom application. <br />
                  1. Log in to a <strong>Squarespace</strong> site.<br />
                  2. In the left nav, click <strong>Settings</strong>; scroll down and click <strong>Advanced</strong>.<br />
                  3. Click <strong>Developer API Keys</strong>.<br />
                  4. Click the <strong>GENERATE KEY</strong> button.<br />
                  5. In the dialog, add a key name under <strong>"KEY NAME"</strong>, and select one or <br /> more Commerce APIs under <strong>"PERMISSIONS"</strong> and permission level.<br />
                  6. Copy <strong>API Key</strong>.
                </div>
                <img src={square_space_api_key} width={300} />
              </>}
              title="Instructions"
              trigger="click"
              // open={open && index === selectIndex}
              // onOpenChange={() => { handleOpenChange(); setSelectIndex(index) }}
              placement="right"
            >
              <FontAwesomeIcon
                style={{ color: '#052977' }}
                className="cursor-pointer ml-2"
                icon={faInfoCircle}
                // onClick={() => setInfoModal(true)}
                title="Instructions"
              />
            </Popover> */}
          </>
        }
        initialValue={formState === "add" ? null : form.getFieldValue().siteUrlSS}
        rules={[
          {
            required: true,
            message: "Please Enter API Key",
          },
        ]}
      >
        <Input
          autoComplete="new-password"
          placeholder="Enter Site Url"
          //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
          size="large"
          onChange={(e) => setPassword(e.target.value)}
          readOnly={formState === "add" ? false : true}
          // onChange={onChange}
          suffix={
            <Tooltip title="Copy Site Url">
              {formState !== 'add' && <Button disabled={formState === 'add'} className="width20" type="text" icon={
                <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue()?.siteUrlSS, 'Site Url')} />
              }>
              </Button>}
            </Tooltip>
          }
        />
      </Form.Item>
      <div className="d-flex justify-content-end">
        {
          formState === 'add' &&
          <Button
            disabled={getProduct && getProduct?.loading}
            // to="#"
            className="default-btn d-inline-flex align-items-center justify-content-center"
            onClick={submit}
          >
            <span className="nav-text">Save</span>
          </Button>}
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const formSiteCore = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
            disabled
          >
            <Option value="siteCore">Sitecore OrderCloud</Option>
            {/* <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option> */}
          </Select>
        </div>

        <div className="col-6 d-flex align-items-center justify-content-end"
        >
          {getSiteCoreDetails?.payload?.connection && validate?.payload?.message?.scoc ?
            getProduct && getProduct?.loading ?
              <div className="d-flex"><span className="spin-color mx-2">
                <Spin size="small" />
              </span><p>Syncing</p></div>
              :
              (
                <Link
                  to="#"
                  className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                  onClick={() => syncData()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                  {/* <Spin spinning={spinner}> */}

                  <span className="nav-text">Sync</span>
                  {/* </Spin> */}
                </Link>
              ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}
        </div>
      </div>
      <Form.Item
        name="shopUrl"
        labelCol={{ span: 24 }}
        label="Enter Shop Url"
        initialValue={formState === "add" ? null : form.getFieldValue().shopUrl}
        rules={[
          {
            required: true,
            message: "Please Enter Shop Url",
          },
          // {
          //   pattern: new RegExp(/^(?!https:\/\/).*/),
          //   message: "https:// not allowed",
          // },
          // {
          //   pattern: new RegExp(/^(?!http:\/\/).*/),
          //   message: "http:// not allowed",
          // },
          {
            // type: 'url',
            pattern: (/^(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/),
            message: 'Enter valid url!',
          },
        ]}
      >
        <Input
          placeholder="Enter Shop Url"
          //value={"test.myshopify.com"}
          size="large"
          // onChange={onChange}
          addonBefore="https://"
          readOnly={formState === "add" ? false : true}
        // addonAfter=".com"
        />
      </Form.Item>
      <Form.Item
        name="buyerId"
        labelCol={{ span: 24 }}
        label="Enter Buyer Id"
        initialValue={formState === "add" ? null : form.getFieldValue().buyerId}
        rules={[
          {
            required: true,
            message: "Please Enter Buyer Id",
          },
        ]}
      >
        <Input
          autoComplete="new-password"
          placeholder="Enter Buyer Id"
          readOnly={formState === "add" ? false : true}
          //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
          size="large"
          onChange={(e) => setPassword(e.target.value)}
          // onChange={onChange}
          suffix={
            <Tooltip title="Copy Buyer Id">
              {formState !== 'add' && <Button disabled={formState === 'add'} className="width20" type="text" icon={
                <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().buyerId, 'Buyer Id')} />
              }>
              </Button>}
            </Tooltip>
          }
        />
      </Form.Item>
      <div className="d-flex justify-content-end">
        {formState == 'add' && <Button
          loading={siteCoreBtn}
          disabled={getProduct && getProduct?.loading}
          // to="#"
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
        >
          <span className="nav-text">Save</span>
        </Button>}
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const formCustom = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-12 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
            disabled
          >
            <Option value="custom">Custom</Option>
            {/* <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option> */}
          </Select>
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end"
        >
          {getShopifyDetails?.payload?.connection && validate?.payload?.message?.shopify ?
            getProduct && getProduct?.loading ?
              <div className="d-flex"><span className="spin-color mx-2">
                <Spin size="small" />
              </span><p>Syncing</p></div>
              :
              (
                <Link
                  to="#"
                  className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                  onClick={() => syncData()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                  <span className="nav-text">Sync</span>
                </Link>
              ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}

        </div>
      </div>
      {/* <Form.Item
        name="shopName"
        labelCol={{ span: 24 }}
        label="Shop Name"
        initialValue={formState === "add" ? null : form.getFieldValue().shopName}
        rules={[
          {
            required: true,
            message: "Please Enter Shop Name",
          },
        ]}
      >
        <Input
          placeholder="Enter Shop Name"
          //value={"test.myshopify.com"}
          size="large"
        />
      </Form.Item> */}
      <Form.Item
        name="domainName"
        labelCol={{ span: 24 }}
        label="Website"
        initialValue={formState === "add" ? null : form.getFieldValue().domainName}
        rules={[
          {
            required: true,
            message: "Please Enter Domain",
          },
          {
            pattern: new RegExp(/^(?!https:\/\/).*/),
            message: "https:// not allowed",
          },
          {
            pattern: new RegExp(/^(?!http:\/\/).*/),
            message: "http:// not allowed",
          },
          {
            // type: 'url',
            pattern: (/^(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/),
            message: 'Enter valid url!',
          },
        ]}
      >
        <Input
          readOnly={formState === "add" ? false : true}
          placeholder="Enter Website"
          //value={"test.myshopify.com"}
          size="large"
          // onChange={onChange}
          addonBefore="https://"
        // addonAfter=".com"
        />
      </Form.Item>
      {/* <Form.Item
        label="Password"
        name="password"
        initialValue={formState === "add" ? null : form.getFieldValue().apikey}
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item> */}
      <Form.Item
        name="apiKeyCustom"
        labelCol={{ span: 24 }}
        label="API Key"
        initialValue={formState === "add" ? null : form.getFieldValue().apiKeyCustom}
      // value={form.getFieldValue().apikey}
      // rules={[
      //   {
      //     required: true,
      //     message: "Please Generate API Key",
      //   },
      // ]}

      >
        <div className="d-flex">
          <Input
            type={visible ? 'text' : 'password'}
            placeholder="Generate API Key"
            // value={password}
            value={form.getFieldValue().apiKeyCustom}
            onChange={(e) => setPassword(e.target.value)}
            readOnly
            suffix={
              <span className="d-flex">
                <Tooltip title="">
                  {visible ? (
                    <EyeTwoTone onClick={toggleVisibility} style={{ marginRight: 10 }} />
                  ) : (
                    <EyeInvisibleOutlined onClick={toggleVisibility} style={{ marginRight: 10 }} />
                  )}
                </Tooltip>
                {formState !== 'add' &&
                  <Tooltip title="Copy api key">
                    <CopyOutlined onClick={() => copyToClipboard(form.getFieldValue().apiKeyCustom, 'API Key')} />
                  </Tooltip>
                }
              </span>
            }
          />
          {/* <Input.Password
            autoComplete="new-password"
            placeholder="Generate API Key"
            //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
            size="large"
            // disabled={true}
            value={form.getFieldValue().apikey}
            readOnly
          // onChange={onChange}

          // addonAfter={
          //   <span onClick={() => copyUrl(form.getFieldValue().apikey)}> < FontAwesomeIcon icon={faFileClipboard} /></span>
          // }
          /> */}
          {/* <Button
            className={`default-btn share-btn d-flex align-items-center justify-content-center `} style={{ width: "58px", height: "45px", minWidth: "initial" }}
            type="primary"
            size="large"
            onClick={() => copyUrl(form.getFieldValue().apikey)}
            title="Delete"
            disabled={form.getFieldValue().apikey === ""}
            icon={
              <FontAwesomeIcon
                size="sm"
                className="event-icon"
                icon={faFileClipboard}
              />
            }
          ></Button> */}
          <Button
            className="default-btn d-inline-flex align-items-center justify-content-center"
            onClick={getGenerateKey}
            loading={keyLoading}
          >
            <span className="nav-text">Generate</span>
          </Button>
        </div>

      </Form.Item>
      <div className="d-flex justify-content-end">
        {formState == 'add' && <Button
          disabled={!form.getFieldValue().apiKeyCustom || form.getFieldValue().apiKeyCustom === ""}
          // to="#"
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
        >
          <span className="nav-text">Save</span>
        </Button>}
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];
  const formCustomSecond = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center justify-content-center">

        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center mb-2"
          key="back"
          // onClick={() => downloadCSV(custom_sample)}
          onClick={downloadCSV}
        >
          Download Sample CSV
        </Button>
        {/* <a href={custom_sample} download="your_file_name.csv">
          <button>Download CSV</button>
        </a> */}
      </div>
      {/* <div>
        <h5>
          CSV Uploading Detail
        </h5>
        <ul>
          <li>...</li>
          <li>...</li>
          <li>...</li>
          <li>...</li>
        </ul>
      </div> */}
      <div className="mb-2">
        <Form.Item
          name="csv_file"
          valuePropName={formState !== "edit" && "fileList"}
          getValueFromEvent={normFile}
          noStyle
          multiple={false}
        >
          <Upload.Dragger
            customRequest={dummyRequest}
            {...uploadFileDragger}
            name="csv_file"
            // className={`upload_area_2 m-auto mb-20 position-relative uploadWidth full-width`}
            className="upload-image-review ecommerce-dragger"
          >
            {/* <FontAwesomeIcon icon={faCloudArrowUp} className="mt-0" /> */}
            <p>Drag & Drop Your File Here</p>
            {/* <h4>Or</h4>
            <Button
              className="default-btn medium-btn txt-btn-pad padb-3">
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
              Select File</Button> */}
          </Upload.Dragger>
        </Form.Item>
        {/* {progress > 0 && <Progress percent={progress} />} */}
      </div>
      <div className="d-flex justify-content-end">
        <Button
          // disabled={getProduct && getProduct?.loading}
          // to="#"
          className="default-btn d-inline-flex align-items-center justify-content-center"
          // onClick={submit}
          disabled={CSVFile === undefined}
          onClick={handleUpload}
        >
          <span className="nav-text">Upload</span>
        </Button>
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const storeChange = (value) => {
    setStore(value);
  };

  const exit = () => {
    setStore("");
    formStore.resetFields();
  };



  return (
    <>
      <div class="title-area justify-content-start">
        <h1 class="page-title">Ecommerce Setup</h1>
        {validate?.payload?.message?.last_sync && <span className="ml-2 fw-bold">( Last Sync: {moment(validate?.payload?.message?.last_sync).format("MM-DD-YYYY h:mm A")} )</span>}
      </div>

      <div className="row">
        <div className="col-xl-6 stream-box-desktop-width">
          <div className="stream-box-white">
            {!validate?.payload?.message?.salesforce &&
              !validate?.payload?.message?.shopify && !validate?.payload?.message?.magento && !validate?.payload?.message?.scoc && !validate?.payload?.message?.customshop && !validate?.payload?.message?.squarespace ? (
              <div className="col-12 mb-20">
                <p className="mbpx-5">Source Type</p>
                <Select
                  className="select-style dark-field w-100 "
                  placeholder="Select Source"
                  size="large"
                  focus={false}
                  onBlur={false}
                  value={handlerStore === "" ? null : handlerStore}
                  // onChange={handleGroupBy}
                  onChange={filterSourceType}
                  disabled={squareSpaceAuthLoader}
                >
                  <Option value="shopify">Shopify</Option>
                  <Option value="salesForce">SalesForce</Option>
                  <Option value="magento">Magento</Option>
                  <Option value="siteCore">Sitecore OrderCloud</Option>
                  {/* <Option value="squareSpace">Square Space</Option> */}
                  <Option value="custom">Custom</Option>
                  {/* <Option value="ordercloud">OrderCloud</Option> */}
                </Select>
              </div>
            ) : null}
            {
              squareSpaceAuthLoader &&
              <span className="mt-2 mr-2 loader-center position-relative">
                <Spin size="large" />
              </span>
            }

            {validate?.payload?.message?.salesforce ? (
              <>
                {loading ? (
                  <span className="mt-2 mr-2 loader-center position-relative">
                    <Spin size="large" />
                  </span>
                ) : getSalesForceDetails?.payload?.data?.message ===
                  "Connection declined!" ? (
                  <div className="col-12 mb-20">
                    <p className="mbpx-5">Source Type</p>
                    <Select
                      className="select-style dark-field w-100 "
                      placeholder="Select Source"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={handlerStore === "" ? null : handlerStore}
                      // onChange={handleGroupBy}
                      onChange={filterSourceType}
                    >
                      <Option value="shopify">Shopify</Option>
                      <Option value="salesForce">SalesForce</Option>
                      {/* <Option value="ordercloud">OrderCloud</Option> */}
                    </Select>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center mb-0">
                    <div className="col">
                      <h5 className="mb-0 simulcasting-hd">SalesForce</h5>
                    </div>
                    <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                      {loadingDetail ? (
                        <span className="mt-2 mr-2 spin-color position-relative">
                          <Spin size="small" />
                        </span>
                      ) : getSalesForceDetails?.payload?.message?.salesforce ? (
                        <>
                          {getSalesForceDetails?.payload?.connection ? (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                              Connected
                            </span>
                          ) : (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                              Not Connected
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                      {/* {getShopifyDetails?.payload?.connection ? (
                  <Link
                    to="#"
                    className="default-btn medium-btn d-inline-flex align-items-center justify-content-center p-0"
                    onClick={() => syncData()}
                  >
                    <FontAwesomeIcon icon={faRotate} />

                    <span className="nav-text">Sync</span>
                  </Link>
                ) : null} */}
                      {/* {spinner && (
                  <div class="sync_loading">
                    <span className="loading_text">Loading</span>
                    &#8230;
                  </div>
                )} */}
                      {
                        // getProduct && getProduct?.loading ? <>
                        //   <span className="mt-2 spin-color position-relative">
                        //     <Spin size="medium" />
                        //   </span>
                        // </>
                        //   :
                        <Button
                          className="default-btn medium-btn padb-3 w-initial"
                          // onClick={showModalFb}
                          onClick={showModal}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>}
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {validate?.payload?.message?.shopify ? (
              <>
                {loading ? (
                  <span className="mt-2 mr-2 loader-center position-relative">
                    <Spin size="large" />
                  </span>
                ) : getShopifyDetails?.payload?.data?.message ===
                  "Connection declined!" ? (
                  <div className="col-12 mb-20">
                    <p className="mbpx-5">Source Type</p>
                    <Select
                      className="select-style dark-field w-100 "
                      placeholder="Select Source"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={handlerStore === "" ? null : handlerStore}
                      // onChange={handleGroupBy}
                      onChange={filterSourceType}
                    >
                      <Option value="shopify">Shopify</Option>
                      <Option value="salesForce">SalesForce</Option>
                      {/* <Option value="ordercloud">OrderCloud</Option> */}
                    </Select>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center mb-0">
                    <div className="col">
                      <h5 className="mb-0 simulcasting-hd">Shopify</h5>
                    </div>
                    <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                      {loadingDetail ? (
                        <span className="mt-2 mr-2 spin-color position-relative">
                          <Spin size="small" />
                        </span>
                      ) : getShopifyDetails?.payload?.message?.shopify ? (
                        <>
                          {getShopifyDetails?.payload?.connection ? (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                              Connected
                            </span>
                          ) : (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                              Not Connected
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                      {/* {getShopifyDetails?.payload?.connection ? (
                    <Link
                      to="#"
                      className="default-btn medium-btn d-inline-flex align-items-center justify-content-center p-0"
                      onClick={() => syncData()}
                    >
                      <FontAwesomeIcon icon={faRotate} />

                      <span className="nav-text">Sync</span>
                    </Link>
                  ) : null} */}
                      {spinner && (
                        <div class="sync_loading">
                          <span className="loading_text">Loading</span>
                          &#8230;
                        </div>
                      )}
                      {
                        /* {getProduct && getProduct?.loading ? <>
                          <span className="mt-2 spin-color position-relative">
                            <Spin size="medium" />
                          </span>
                        </>
                          : */
                        <Button
                          className="default-btn medium-btn padb-3 w-initial"
                          // onClick={showModalFb}
                          onClick={showModal}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>}
                    </div>
                  </div>
                )}
              </>
            ) : null}
            {validate?.payload?.message?.magento ? (
              <>
                {loading ? (
                  <span className="mt-2 mr-2 loader-center position-relative">
                    <Spin size="large" />
                  </span>
                ) : getMagentoDetails?.payload?.message ===
                  "Connection declined!" ? (
                  <div className="col-12 mb-20">
                    <p className="mbpx-5">Source Type</p>
                    <Select
                      className="select-style dark-field w-100 "
                      placeholder="Select Source"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={handlerStore === "" ? null : handlerStore}
                      // onChange={handleGroupBy}
                      onChange={filterSourceType}
                    >
                      <Option value="shopify">Shopify</Option>
                      <Option value="salesForce">SalesForce</Option>
                      {/* <Option value="magento">Magento</Option> */}
                      {/* <Option value="ordercloud">OrderCloud</Option> */}
                    </Select>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center mb-0">
                    <div className="col">
                      <h5 className="mb-0 simulcasting-hd">Magento</h5>
                    </div>
                    <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                      {loadingDetail ? (
                        <span className="mt-2 mr-2 spin-color position-relative">
                          <Spin size="small" />
                        </span>
                      ) : getMagentoDetails?.payload?.message ? (
                        <>
                          {getMagentoDetails?.payload?.connection ? (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                              Connected
                            </span>
                          ) : (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                              Not Connected
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                      {/* {getShopifyDetails?.payload?.connection ? (
                    <Link
                      to="#"
                      className="default-btn medium-btn d-inline-flex align-items-center justify-content-center p-0"
                      onClick={() => syncData()}
                    >
                      <FontAwesomeIcon icon={faRotate} />

                      <span className="nav-text">Sync</span>
                    </Link>
                  ) : null} */}
                      {spinner && (
                        <div class="sync_loading">
                          <span className="loading_text">Loading</span>
                          &#8230;
                        </div>
                      )}
                      {
                        // getProduct && getProduct?.loading ? <>
                        //   <span className="mt-2 spin-color position-relative">
                        //     <Spin size="medium" />
                        //   </span>
                        // </>
                        //   :
                        <Button
                          className="default-btn medium-btn padb-3 w-initial"
                          // onClick={showModalFb}
                          onClick={showModal}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      }
                    </div>
                  </div>
                )}
              </>
            ) : null}
            {validate?.payload?.message?.scoc ? (
              <>
                {loading ? (
                  <span className="mt-2 mr-2 loader-center position-relative">
                    <Spin size="large" />
                  </span>
                ) : getSiteCoreDetails?.payload?.data?.message ===
                  "Connection declined!" ? (
                  <div className="col-12 mb-20">
                    <p className="mbpx-5">Source Type</p>
                    <Select
                      className="select-style dark-field w-100 "
                      placeholder="Select Source"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={handlerStore === "" ? null : handlerStore}
                      // onChange={handleGroupBy}
                      onChange={filterSourceType}
                    >
                      <Option value="shopify">Shopify</Option>
                      <Option value="salesForce">SalesForce</Option>
                      <Option value="siteCore">Sitecore OrderCloud</Option>
                      {/* <Option value="ordercloud">OrderCloud</Option> */}
                    </Select>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center mb-0">
                    <div className="col">
                      <h5 className="mb-0 simulcasting-hd">Sitecore OrderCloud</h5>
                    </div>
                    <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                      {loadingDetail ? (
                        <span className="mt-2 mr-2 spin-color position-relative">
                          <Spin size="small" />
                        </span>
                      ) : getSiteCoreDetails?.payload?.message?.scoc ? (
                        <>
                          {getSiteCoreDetails?.payload?.connection ? (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                              Connected
                            </span>
                          ) : (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                              Not Connected
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                      {/* {getShopifyDetails?.payload?.connection ? (
                    <Link
                      to="#"
                      className="default-btn medium-btn d-inline-flex align-items-center justify-content-center p-0"
                      onClick={() => syncData()}
                    >
                      <FontAwesomeIcon icon={faRotate} />

                      <span className="nav-text">Sync</span>
                    </Link>
                  ) : null} */}
                      {spinner && (
                        <div class="sync_loading">
                          <span className="loading_text">Loading</span>
                          &#8230;
                        </div>
                      )}
                      {
                        /* {getProduct && getProduct?.loading ? <>
                          <span className="mt-2 spin-color position-relative">
                            <Spin size="medium" />
                          </span>
                        </>
                          : */
                        <Button
                          className="default-btn medium-btn padb-3 w-initial"
                          // onClick={showModalFb}
                          onClick={showModal}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>}
                    </div>
                  </div>
                )}
              </>
            ) : null}
            {validate?.payload?.message?.squarespace ? (
              <>
                {loading ? (
                  <span className="mt-2 mr-2 loader-center position-relative">
                    <Spin size="large" />
                  </span>
                ) : getSquareSpaceDetails?.payload?.data?.message?.squarespace ===
                  "Connection declined!" ? (
                  <div className="col-12 mb-20">
                    <p className="mbpx-5">Source Type</p>
                    <Select
                      className="select-style dark-field w-100 "
                      placeholder="Select Source"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={handlerStore === "" ? null : handlerStore}
                      // onChange={handleGroupBy}
                      onChange={filterSourceType}
                    >
                      <Option value="shopify">Shopify</Option>
                      <Option value="salesForce">SalesForce</Option>
                      <Option value="siteCore">Sitecore OrderCloud</Option>
                      <Option value="squareSpace">Square Space</Option>
                      {/* <Option value="ordercloud">OrderCloud</Option> */}
                    </Select>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center mb-0">
                    <div className="col">
                      <h5 className="mb-0 simulcasting-hd">Square Space</h5>
                    </div>
                    <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                      {loadingDetail ? (
                        <span className="mt-2 mr-2 spin-color position-relative">
                          <Spin size="small" />
                        </span>
                      ) : getSquareSpaceDetails?.payload?.message?.squarespace ? (
                        <>
                          {getSquareSpaceDetails?.payload?.connection ? (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                              Connected
                            </span>
                          ) : (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                              Not Connected
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                      {/* {getShopifyDetails?.payload?.connection ? (
                    <Link
                      to="#"
                      className="default-btn medium-btn d-inline-flex align-items-center justify-content-center p-0"
                      onClick={() => syncData()}
                    >
                      <FontAwesomeIcon icon={faRotate} />

                      <span className="nav-text">Sync</span>
                    </Link>
                  ) : null} */}
                      {spinner && (
                        <div class="sync_loading">
                          <span className="loading_text">Loading</span>
                          &#8230;
                        </div>
                      )}
                      {
                        /* {getProduct && getProduct?.loading ? <>
                          <span className="mt-2 spin-color position-relative">
                            <Spin size="medium" />
                          </span>
                        </>
                          : */
                        <Button
                          className="default-btn medium-btn padb-3 w-initial"
                          // onClick={showModalFb}
                          onClick={showModal}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>}
                    </div>
                  </div>
                )}
              </>
            ) : null}
            {validate?.payload?.message?.customshop ? (
              <>
                {loading ? (
                  <span className="mt-2 mr-2 loader-center position-relative">
                    <Spin size="large" />
                  </span>
                ) : getCustomShopDetails?.payload?.data?.message ===
                  "Connection declined!" ? (
                  <div className="col-12 mb-20">
                    <p className="mbpx-5">Source Type</p>
                    <Select
                      className="select-style dark-field w-100 "
                      placeholder="Select Source"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={handlerStore === "" ? null : handlerStore}
                      // onChange={handleGroupBy}
                      onChange={filterSourceType}
                    >
                      <Option value="shopify">Shopify</Option>
                      <Option value="salesForce">SalesForce</Option>
                      {/* <Option value="ordercloud">OrderCloud</Option> */}
                    </Select>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center mb-0">
                    <div className="col">
                      <h5 className="mb-0 simulcasting-hd">Custom</h5>
                    </div>
                    <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                      {loadingDetail ? (
                        <span className="mt-2 mr-2 spin-color position-relative">
                          <Spin size="small" />
                        </span>
                      ) : getCustomShopDetails?.payload?.message ? (
                        <>
                          {getCustomShopDetails?.payload?.success ? (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                              Connected
                            </span>
                          ) : (
                            <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                              Not Connected
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                      {/* {getShopifyDetails?.payload?.connection ? (
                    <Link
                      to="#"
                      className="default-btn medium-btn d-inline-flex align-items-center justify-content-center p-0"
                      onClick={() => syncData()}
                    >
                      <FontAwesomeIcon icon={faRotate} />

                      <span className="nav-text">Sync</span>
                    </Link>
                  ) : null} */}
                      {spinner && (
                        <div class="sync_loading">
                          <span className="loading_text">Loading</span>
                          &#8230;
                        </div>
                      )}
                      {
                        addCustomProducts && addCustomProducts?.loading ?
                          <>
                            <span className="mt-2 spin-color position-relative">
                              <Spin size="medium" />
                            </span>
                          </>
                          :
                          <>
                            <Button
                              className="default-btn medium-btn padb-3 w-initial"
                              // onClick={showModalFb}
                              onClick={showModal}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                            {/* {spinner && (
                        <div class="sync_loading">
                        <span className="loading_text">Loading</span>
                        &#8230;
                        </div>
                      )} */}
                            {/* Upload Product btn */}
                            <Button
                              className="default-btn medium-btn padb-3 w-initial"
                              onClick={() => setIsModalCustomSecond(true)}
                            >
                              Upload Products
                            </Button>
                          </>
                      }
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {/* <div className="row d-flex align-items-center mb-20">
              <div className="col">
                <h5 className="mb-0 simulcasting-hd">Oracle</h5>
              </div>
              <div className="col-auto col-auto d-flex  justify-content-end  pl-0">
                <Button
                  className="default-btn medium-btn padb-3 ml-0"
                  // onClick={showModal}
                >
                  Configure
                </Button>
              </div>
            </div> */}
            {/* <div className="row d-flex align-items-center mb-20">
              <div className="col">
                <h5 className="mb-0 simulcasting-hd">Magento</h5>
              </div>
              <div className="col-auto col-auto d-flex  justify-content-end  pl-0">
                <Button
                  className="default-btn medium-btn padb-3 ml-0"
                  // onClick={showModal}
                >
                  Configure
                </Button>
              </div>
            </div>
            <div className="row d-flex align-items-center mb-0">
              <div className="col">
                <h5 className="mb-0 simulcasting-hd">OrderCloud</h5>
              </div>
              <div className="col-auto col-auto d-flex  justify-content-end  pl-0">
                <Button
                  className="default-btn medium-btn padb-3 ml-0"
                  // onClick={showModal}
                >
                  Configure
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <Form form={formStore}>
        <div className="row">
          <div className="col-xl-3 col-md-6 col-12">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Select Store"
              name="store"
            >
              <Select
                className="select-style dark-field store-select"
                placeholder="Select Store"
                size="large"
                focus={false}
                onBlur={false}
                onChange={storeChange}
                // value={store}
                // defaultValue="shopify"
              >
                <Option value="shopify">Shopify</Option>
                <Option value="oracle">Oracle</Option>
                <Option value="magento">Magento</Option>
                <Option value="ordercloud">orderCloud</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end">
            <Form.Item className="d-flex align-items-end">
              {store && (
                // <div className="col-xl-3 col-md-6 col-12">
                <Button
                  className="default-btn medium-btn fltr-hpr m-0"
                  onClick={() => exit()}
                >
                  Exit
                </Button>
                // </div>
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
      {store === 'shopify' && <Form {...formProps}>{formList[current]}</Form>}
      {store === 'oracle' && <Form {...formProps}>{formOracle[current]}</Form>}
      {store === 'ordercloud' && (
        <Form {...formProps}>{formOrdercloud[current]}</Form>
      )}
      {store === 'magento' && (
        <Form {...formProps}>{formMagento[current]}</Form>
      )} */}
      {/* SHOPIFY MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loadingDetail ? (
          <Form {...formProps}>{formList[current]}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* MAGENTO MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenMagento}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loading ? (
          <Form {...formProps}>{formMagento}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* MAGENTO SECOND MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenMagentoSecond}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Select Store</h2>
        {!loading ? (
          <Form {...formProps}>{formMagentoSecond}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* OrderCloud MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenCloud}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loading ? (
          <Form {...formProps}>{formOrdercloud}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* SALESFORCE MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalSalesForce}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loadingDetail ? (
          <Form {...formProps}>{formSalesForce}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* SQUARE SPACE MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenSquareSpace}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loadingDetail ? (
          <Form {...formProps}>{formSquareSpace[current]}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* SITE CORE MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenSiteCore}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loadingDetail ? (
          <Form {...formProps}>{formSiteCore[current]}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* CUSTOM MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalCustom}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loading ? (
          <Form {...formProps}>{formCustom}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>


      {/* CUSTOM SECOND MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalCustomSecond}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Upload Products</h2>
        {!loading ? (
          <Form {...formProps}>{formCustomSecond}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>
    </>
  );
}
export default EcommerceSetup;